import styled from "styled-components";

export const VerifySucessColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const LogoImg = styled.img`
  width: 125px !important;
  border-radius: 1000px !important;
  border: 10px solid #323232;
  position: relative;
  bottom: 2rem;
  @media (max-width: 768px) {
    width: 80px !important;
  }
`;

export const VerifySucessH1 = styled.h1`
  font-size: 40px;
  color: #000;
  background-color: #50cb93;
  padding: 16px 30px;
  border-radius: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const VerifyContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const NeedLink = styled.p`
  color: #ebd788;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 10px;
    position: relative;
    bottom: 7px;
  }
`;

export const VerifyContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  position: relative;
  top: 60px;

  @media (max-width: 768px) {
    top: 0;
  }
`;

export const ContinueBtn = styled.button`
  border-radius: 0px 1px 20px 20px;
  position: relative;
  bottom: 28px;
  right: 20px;
  background-color: #ebd788;
  border: none;
  outline: none;
  padding: 4px 50px;
  height: 40px;

  @media (max-width: 768px) {
    height: 20px;
    padding: 4px 20px;
    font-size: 10px;
    bottom: 10px;
  }
`;
