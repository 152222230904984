import {
  GetTicketList,
  GetTicketMessage,
  SeenTicket,
  SendMessage,
} from "../../hooks/ticket";

export const GetTicketListAction = () => {
  return async (dispatch) => {
    const response = await GetTicketList();
    await dispatch({
      type: "GET_TICKET_LIST",
      payload: response.data.reverse(),
    });
  };
};

export const GetTicketMessageAction = (id) => {
  return async (dispatch) => {
    const response = await GetTicketMessage(id);
    await dispatch({
      type: "GET_TICKET_MSG",
      payload: response.data,
    });
    await dispatch({
      type: "GET_AC_TICKET",
      payload: response.active,
    });
  };
};

export const SendMessageAction = (id, message, success) => {
  return async (dispatch) => {
    const response = await SendMessage(id, message);
    if (response) {
      success();
    }
  };
};

export const SeenTicketAction = (info) => {
  return async (dispatch) => {
    await SeenTicket(info);
    dispatch(GetTicketListAction());
  };
};
