import React, { useEffect, useState } from "react";
import {
  AuthContainer,
  AuthFluid,
  FieldError,
  FieldSuccess,
  FieldWrapper,
  HomeSide,
  HomeSideImg,
  LoginContent,
  LoginForm,
  LoginInput,
  LoginSide,
  LoginTitle,
  RegisterBtn,
  RegisterContent,
  RegisterForm,
  RegisterInput,
  RegisterSide,
  RegisterTitle,
  InputiLabel,
  BorderBottom,
  FieldWrapperRegister,
  BtnLoginWrapper,
  ForgetLink,
  ButtonLogin,
} from "./Elements";
import Logo from "../img/logo.png";
import { Check, ViewArrayTwoTone } from "@material-ui/icons";
import "./LooginBorderStyle.css";
import { BaseUrl } from "../../../../apis/GlobalConfing";
import { useDispatch } from "react-redux";
import { ForgetPasswordDialog } from "./ForgetPasswordDialog";
import { LoginUserAction } from "../../../../redux/auth/login.action";
import { RegisterUserAction } from "../../../../redux/auth/register.action";
const qr = require("querystring");

const DesktopMain = ({ history }) => {
  const dispatch = useDispatch();
  // Register
  const [checkEmail, setCheckEmail] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const invitation_ref = urlParams.get("ref");

  const [showForm, setShowForm] = useState("index");
  const [showRegister, setShowRegister] = useState(false);

  const [registerForm, setRegisterForm] = useState({
    name: "",
    email: "",
    password: "",
    confirm: "",
    invitation_code: invitation_ref,
  });

  const reset = () => {
    setRegisterForm({
      email: "",
      password: "",
      confirm_password: "",
      name: "",
      phone: "",
    });
  };
  //Forget Password
  const [open_dialog, setOpenDialog] = useState(false);
  // Login
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [loginError, setLoginErr] = useState({
    show: false,
    text: "",
  });
  const handleLoginError = (text) => {
    setLoginErr((prev) => ({ ...prev, show: true, text: text }));
  };
  const handleLoginUser = () => {
    if (loginForm.password.length >= 8) {
      dispatch(LoginUserAction(loginForm, handleLoginError));
    } else {
      handleLoginError("Password must be 8 characters long");
    }
  };

  //   Validation Register Name
  const [validationName, setValidationName] = useState({
    error: false,
    success: false,
  });

  const handleCheckName = () => {
    if (registerForm.name.length == 0) {
      setValidationName((prevState) => ({
        ...prevState,
        error: true,
        success: false,
      }));
    } else {
      setValidationName((prevState) => ({
        ...prevState,
        success: true,
        error: false,
      }));
    }
  };
  //   Validation Register Email
  const [validationEmail, setValidationEmail] = useState({
    error: false,
    success: false,
  });

  const handleCheckEmail = async () => {
    const response = await fetch(`${BaseUrl}/user/cheackemail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qr.stringify({ email: registerForm.email }),
    });
    const data = await response.json();

    setCheckEmail(data);
    const isValid = registerForm.email.indexOf("@");

    const CheckValidEmailPass = () => {
      var result = null;
      const enterEmail = registerForm.email.toLowerCase();
      const isYahoo = enterEmail.search("yahoo");
      const isGmail = enterEmail.search("gmail");
      const isOutlook = enterEmail.search("outlook");
      const isHotmail = enterEmail.search("hotmail");
      if (
        isYahoo == -1 &&
        isGmail == -1 &&
        isOutlook == -1 &&
        isHotmail == -1
      ) {
        result = false;
      } else {
        result = true;
      }

      return result;
    };
    const validEmailType = CheckValidEmailPass();

    if (
      registerForm.email.length == 0 ||
      data == true ||
      isValid == -1 ||
      validEmailType == false
    ) {
      setValidationEmail((prevState) => ({
        ...prevState,
        error: true,
        success: false,
      }));
    } else {
      setValidationEmail((prevState) => ({
        ...prevState,
        success: true,
        error: false,
      }));
    }
  };

  //   Validation Register Password
  const [validationPassword, setValidationPassword] = useState({
    error: false,
    success: false,
    text: "",
  });

  const handleCheckPassword = () => {
    if (registerForm.password == registerForm.password.toUpperCase()) {
      console.log(" Uppercase");
    } else {
      console.log("Lowercase");
    }
    if (registerForm.password.length < 8) {
      setValidationPassword((prevState) => ({
        ...prevState,
        error: true,
        success: false,
        text: "Must 8 charactor",
      }));
    } else if (registerForm.password.length == 0) {
      setValidationPassword((prevState) => ({
        ...prevState,
        error: true,
        success: false,
        text: "Password not valid",
      }));
    } else {
      setValidationPassword((prevState) => ({
        ...prevState,
        success: true,
        error: false,
      }));
    }
  };
  //   Validation Register Confirm
  const [validationConfirm, setValidationConfirm] = useState({
    error: false,
    success: false,
    text: "",
  });

  const handleCheckConfirm = () => {
    if (registerForm.password.length == 0) {
      setValidationConfirm((prevState) => ({
        ...prevState,
        error: true,
        success: false,
        text: "Passwords not match",
      }));
    } else if (registerForm.password != registerForm.confirm) {
      setValidationConfirm((prevState) => ({
        ...prevState,
        error: true,
        success: false,
        text: "Passwords not match",
      }));
    } else {
      setValidationConfirm((prevState) => ({
        ...prevState,
        success: true,
        error: false,
      }));
    }
  };

  const handleShowLogin = () => {
    setShowForm("login");
    setShowRegister(true);
  };
  const handleShowRegister = () => {
    setShowForm("register");
    setShowRegister(true);
  };
  const handleShowHome = () => {
    setShowForm("index");
    setShowRegister(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      window.location.replace("/home");
    }
  }, [checkEmail]);

  const handleRegisterUser = () => {
    handleCheckName();
    handleCheckPassword();
    handleCheckEmail();
    handleCheckConfirm();
    if (validationName.success != true) {
      setValidationName((prevState) => ({
        ...prevState,
        error: true,
        success: false,
      }));
    } else if (validationPassword.success != true) {
      setValidationPassword((prevState) => ({
        ...prevState,
        error: true,
        success: false,
      }));
    } else if (validationConfirm.success != true) {
      setValidationConfirm((prevState) => ({
        ...prevState,
        error: true,
        success: false,
      }));
    } else if (
      validationEmail.success != true &&
      registerForm.password != registerForm.confirm
    ) {
      setValidationEmail((prevState) => ({
        ...prevState,
        error: true,
        success: false,
      }));
    } else {
      localStorage.setItem("e", registerForm.email);
      dispatch(RegisterUserAction(registerForm, reset, history));
    }
  };

  return (
    <AuthFluid>
      <AuthContainer showForm={showForm}>
        <RegisterSide showForm={showForm} showRegister={showRegister}>
          <RegisterContent showForm={showForm}>
            <RegisterTitle showForm={showForm} onClick={handleShowRegister}>
              Register
            </RegisterTitle>
            <RegisterForm showForm={showForm}>
              <FieldWrapperRegister>
                <RegisterInput
                  placeholder="Name"
                  autoComplate="off"
                  onBlur={handleCheckName}
                  onChange={(e) =>
                    setRegisterForm((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                {validationName.error == false ? null : (
                  <FieldError>Name not valid</FieldError>
                )}
                {validationName.success == false ? null : (
                  <FieldSuccess>
                    <Check />
                  </FieldSuccess>
                )}
              </FieldWrapperRegister>
              <FieldWrapperRegister>
                <RegisterInput
                  onBlur={handleCheckEmail}
                  onChange={(e) =>
                    setRegisterForm((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  placeholder="Email"
                  autoComplate="off"
                  type="email"
                  required
                />
                {validationEmail.error == false ? null : (
                  <FieldError>Email not valid</FieldError>
                )}
                {validationEmail.success == false ? null : (
                  <FieldSuccess>
                    <Check />
                  </FieldSuccess>
                )}
              </FieldWrapperRegister>
              <FieldWrapperRegister>
                <RegisterInput
                  onBlur={handleCheckPassword}
                  onChange={(e) =>
                    setRegisterForm((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  placeholder="Password"
                  type="password"
                  autoComplate="off"
                />
                {validationPassword.error == false ? null : (
                  <FieldError
                    style={{
                      right: `${
                        registerForm.password.length < 8 ? "-140px" : "-150px"
                      }`,
                    }}
                  >
                    {validationPassword.text}
                  </FieldError>
                )}
                {validationPassword.success == false ? null : (
                  <FieldSuccess>
                    <Check />
                  </FieldSuccess>
                )}
              </FieldWrapperRegister>
              <FieldWrapperRegister>
                <RegisterInput
                  placeholder="Confirm-Password"
                  type="password"
                  autoComplate="off"
                  onBlur={handleCheckConfirm}
                  onChange={(e) =>
                    setRegisterForm((prev) => ({
                      ...prev,
                      confirm: e.target.value,
                    }))
                  }
                  disabled={registerForm.password.length < 8 ? true : false}
                />
                {validationConfirm.error == false ? null : (
                  <FieldError style={{ right: "-165px" }}>
                    {validationConfirm.text}
                  </FieldError>
                )}
                {validationConfirm.success == false ? null : (
                  <FieldSuccess>
                    <Check />
                  </FieldSuccess>
                )}
              </FieldWrapperRegister>

              <FieldWrapperRegister>
                <RegisterInput
                  placeholder="Invitation-Code"
                  autoComplate="off"
                  value={registerForm.invitation_code}
                  onChange={(e) =>
                    setRegisterForm((prev) => ({
                      ...prev,
                      invitation_code: e.target.value,
                    }))
                  }
                />
              </FieldWrapperRegister>
            </RegisterForm>
            <RegisterBtn showForm={showForm} onClick={handleRegisterUser}>
              Register
            </RegisterBtn>
          </RegisterContent>
        </RegisterSide>
        <HomeSideImg
          align="left"
          src={Logo}
          showForm={showForm}
          onClick={handleShowHome}
        />
        <LoginSide showForm={showForm}>
          <LoginContent showForm={showForm}>
            <LoginTitle showForm={showForm} onClick={handleShowLogin}>
              Login
            </LoginTitle>
            <LoginForm showForm={showForm}>
              <FieldWrapper>
                <InputiLabel>Email</InputiLabel>
                <LoginInput
                  onChange={(e) => {
                    setLoginErr((prev) => ({
                      ...prev,
                      show: false,
                      text: "text",
                    }));
                    setLoginForm((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  className="Winput"
                  placeholder="Email"
                />
                <BorderBottom className="BorderBottom"></BorderBottom>
              </FieldWrapper>
              <FieldWrapper>
                <InputiLabel>Password</InputiLabel>
                <LoginInput
                  className="Winput"
                  onChange={(e) => {
                    setLoginErr((prev) => ({
                      ...prev,
                      show: false,
                      text: "text",
                    }));
                    setLoginForm((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                  placeholder="Password"
                  type="password"
                />
                <BorderBottom className="BorderBottom"></BorderBottom>
              </FieldWrapper>
              {loginError.show == false ? null : (
                <p
                  style={{
                    background: "red",
                    borderRadius: "35px",
                    color: "#fff",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  {loginError.text}
                </p>
              )}
            </LoginForm>
            <BtnLoginWrapper showForm={showForm}>
              <ForgetLink onClick={() => setOpenDialog(true)}>
                Forget password?
              </ForgetLink>
              <ButtonLogin onClick={handleLoginUser}>Confirm</ButtonLogin>
            </BtnLoginWrapper>
          </LoginContent>
        </LoginSide>
      </AuthContainer>
      <ForgetPasswordDialog
        open={open_dialog}
        close={() => setOpenDialog(false)}
      />
    </AuthFluid>
  );
};

export default DesktopMain;
