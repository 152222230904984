import { VerifyActiveTwoAuth } from "../../hooks/auth";
import { UseLoginUser } from "../../hooks/login";
import {
  ConnectionError,
  ErrorMessage,
  SuccessMessage,
} from "./../../utils/messages";
import JWT from "jsonwebtoken";

export const LoginUserAction = (user_info, handleLoginError) => {
  return async () => {
    if (user_info.email == undefined || user_info.email == "") {
      handleLoginError("Please Enter Your Email");
    } else if (user_info.password == undefined || user_info.password == "") {
      handleLoginError("Please Enter Your Password");
    } else if (
      user_info.email != undefined ||
      user_info.password != undefined
    ) {
      const response = await UseLoginUser(user_info);
      if (response.active_eamil == true) {
        if (response.success == true) {
          if (response.authenticator == false) {
            window.location.replace("/home");
            localStorage.setItem("token", response.data.token);
          } else if (response.authenticator == true) {
            localStorage.setItem("token", response.token);
            window.location.replace("/auth/two-auth");
          }
        } else {
          handleLoginError(response.data);
        }
      } else {
        localStorage.setItem("v_t", response.token);
        SuccessMessage(response.data);
        window.location.replace("/auth/verify-email");
        const email = JWT.decode(response.token, { complete: true }).payload
          .email;
        localStorage.setItem("e", email);
      }
    }
  };
};
export const VerifyTwoAuthAction = (code, history) => {
  return async () => {
    const response = await VerifyActiveTwoAuth(code);
    console.log(response);
    if (response.success == true) {
      history.replace("/home");
      localStorage.setItem("token", response.data.token);
      SuccessMessage("Authentication Successful");
    } else {
      ErrorMessage("Authentication Faild");
    }
  };
};

export const LoginAction = () => ({ type: "ShowLogin" });

export const CloseSection = () => ({ type: "CloseSection" });

export const CloseSectionSignUp = () => ({ type: "CloseSectionSignUp" });

export const SignUpAction = () => ({ type: "ShowSignUp" });
