import { BaseUrl } from "./../apis/GlobalConfing";
const qr = require("querystring");

export const UseGetUserInfo = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(`${BaseUrl}/user/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify({token})
  });
  const data = await response.json();

  return data;
};

export const ChangeUserName = async (name) => {
  const token = localStorage.getItem("token");
  const user_info = {
    token,
    name,
  };
  const response = await fetch(`${BaseUrl}/user/editname`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(user_info),
  });
  const data = await response.json();

  return data;
};

export const ChangeUserProfile = async (dt) => {
  const response = await fetch(`${BaseUrl}/user/editpic`, {
    method: "POST",
    body: dt,
  });
  const data = await response.json();

  return data;
};

export const ActiveTwoAuth = async () => {
  const info = { token: localStorage.getItem("token") };
  const response = await fetch(`${BaseUrl}/user/activeauth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const TurnOffTwoAuth = async (code) => {
  const info = { token: localStorage.getItem("token"), code };
  const response = await fetch(`${BaseUrl}/user/deleteauth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
export const ComplateTwoAuth = async (code) => {
  const info = { token: localStorage.getItem("token"), code };

  const response = await fetch(`${BaseUrl}/user/confirmauth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const VerifyActiveTwoAuth = async (code) => {
  const info = { token: localStorage.getItem("token"), vftoken: code };
  const response = await fetch(`${BaseUrl}/user/verifyath`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const ChangePassword = async (code) => {
  const info = { token: localStorage.getItem("token"), password: code };
  const response = await fetch(`${BaseUrl}/user/updateuserpass`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
export const ChangeEmail = async (code) => {
  const info = { token: localStorage.getItem("token"), email: code };
  const response = await fetch(`${BaseUrl}/user/changeemail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const VerifyChangeEmail = async (code) => {
  const info = { token: localStorage.getItem("chet"), code: code };
  const response = await fetch(`${BaseUrl}/user/changeemail2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const ForgetPasswordFirst = async (info) => {
  const response = await fetch(`${BaseUrl}/user/forgetpassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
export const ForgetPasswordVerify = async (info) => {
  const response = await fetch(`${BaseUrl}/user/forgetpassword2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const GetSeensLength = async (code) => {
  const info = { token: localStorage.getItem("token") };
  const response = await fetch(`${BaseUrl}/home/Notifandticketseen`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
