import { toast } from "material-react-toastify";

export const ConnectionError = () => {
  toast.error("Have Problem, Please Check Your Internet Connection");
};

export const ErrorMessage = (text) => {
  toast.error(text);
};
export const SuccessMessage = (text) => {
  toast.success(text);
};
