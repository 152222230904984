import { combineReducers } from "redux";
import { GenealogyReducer } from "./genealogy/genealogy.reducer";
import {
  AuthReducer,
  LoginReducer,
  SeensReducer,
  SignUpReducer,
  TwoAuthReducer,
} from "./auth/auth.reducer";
import { WalletReducer } from "./wallet/wallet.reducer";
import { loadingReducer, TransactionReducer } from "./transaction/transaction.reducer";
import { PlanReducer } from "./plan/plan.reducer";
import { ChatReducer } from "./chats/ChatReducer";
import { TicketReducer } from "./tickets/tickets.reducer";
import { NotificationReduser } from "./notifications/notifications.reducer";
import { documentReducer } from './documnet/document.reducer';

export const reducers = combineReducers({
  GenealogyUsers: GenealogyReducer,
  AuthenticationUser: AuthReducer,
  TwoAuthInfo: TwoAuthReducer,
  Wallet: WalletReducer,
  Transaction: TransactionReducer,
  ShowLogin: LoginReducer,
  ShowSignUp: SignUpReducer,
  Plan: PlanReducer,
  showChat: ChatReducer,
  Tickets: TicketReducer,
  Notification: NotificationReduser,
  Seens: SeensReducer,
  loading: loadingReducer,
  document: documentReducer
});
