import React, { useEffect } from "react";
import "./layout.css";
import TemporaryDrawer from "../sidebar/sidebarmain";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Sidebar from "../sidebar/index";
import ScrollTop from "../totop";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import socket from "./../../../socket/index";
import jwt from 'jsonwebtoken';
import { withRouter } from "react-router";

const Layout = (props) => {
  const { children, window } = props;
  const matches = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    const token = localStorage.getItem("token");
    // socket.emit("get_user_transction", { token });
    // socket.emit("send_user_transction", { token });
    if (token) {
      const decodedToken = jwt.decode(token, {complete: true});
      if (decodedToken.payload.exp < (Date.now() / 1000)) {
        localStorage.removeItem('token');
        props.history.replace('/login');
      }
    }
  }, []);
  return (
    <>
      {matches ? (
        <div className="layoutContainer">
          <div className="minContainer">
            <TemporaryDrawer content={children} />
            <ScrollTop {...props}>
              <Fab
                color="secondary"
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
          </div>
        </div>
      ) : (
        <div className="layoutContainer">
          <div className="minContainer ">
            <Sidebar />

            <div className="contents ">
              {children}
              <ScrollTop {...props}>
                <Fab
                  color="secondary"
                  size="small"
                  aria-label="scroll back to top"
                >
                  <KeyboardArrowUpIcon />
                </Fab>
              </ScrollTop>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default withRouter(Layout);
