import React from 'react';

const Input = ({ label, id, tooltip, children, ...props }) => {
	return (
		<div className="input-group">
			<label htmlFor={id}>{label}</label>

			<div className="content-box">
				{tooltip &&
					<div className="tooltip">
						<span>{tooltip}</span>
					</div>
				}

				<div className="input-box">
					<input type="text" id={id} {...props} />

					<button className="select">
						{children}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Input;
