import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { TurnOffTwoAuthAction } from "../../../redux/auth/auth.action";
import { ErrorMessage } from "../../../utils/messages";

export const TurnOffTwoAuthDialog = ({ open, close, success }) => {
  const [code, setCode] = React.useState("");
  const dispatch = useDispatch();

  const handleTurnOff = () => {
    if (code != "") {
      dispatch(TurnOffTwoAuthAction(success, code));
    } else {
      ErrorMessage("Please enter authenticator code");
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Turn OFF Two-Facktor Authentication"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want Trurning Off the <b>Two-Facktor Authentication</b> of
            your account? Please enter the <b>Authenticator Code</b> for turn
            off.
          </DialogContentText>
          <input
            className="forget-pass-field"
            placeholder="Enter authenticator code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Disagree
          </Button>
          <Button onClick={handleTurnOff} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
