import { BaseUrl } from "../apis/GlobalConfing";

const qr = require("querystring");

export const GetPlanList = async () => {
  const info = { token: localStorage.getItem("token") };
  const response = await fetch(`${BaseUrl}/blockchain/planlist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
export const GetPlanHomeList = async () => {
  const info = { token: localStorage.getItem("token") };
  const response = await fetch(`${BaseUrl}/blockchain/planInfo`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const ActivePlan = async (user_info) => {
  const response = await fetch(`${BaseUrl}/blockchain/newPlan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(user_info),
  });
  const data = await response.json();

  return data;
};
export const GetActivePlanList = async () => {
  const info = { token: localStorage.getItem("token") };
  const response = await fetch(`${BaseUrl}/blockchain/planactive`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
export const AddPriceToPlan = async (amount) => {
  const info = { token: localStorage.getItem("token"), amount };
  const response = await fetch(`${BaseUrl}/blockchain/pricetoplan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};
