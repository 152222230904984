import React, { useState } from "react";
import "./historytitle.css";
import icon from "../../../../assets/history.svg";
import Transactions from "./history.object";
import { ReactComponent as History } from "../../../../assets/history.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  DateRow,
  MainContainer,
  SelectBox,
  DateText,
  Divider,
  TXRow,
  TypeTx,
  TextContainer,
  TypeAction,
  AmountText,
  AmountTx,
  SvgContainer,
} from "./history.styled";
import { useSelector } from "react-redux";

const Index = () => {
  const [date] = useState(new Date());
  const Transaction = useSelector((state) => state.Transaction);
  const wallet = useSelector((state) => state.Wallet);

  const fliterNumber = (number) => {
    const num = number * 0.000000000000000001;
    const numIndex = num.toString().indexOf(".");
    const new_number = num.toString().slice(0, numIndex + 5);
    return new_number;
  };

  return (
    <>
      <div className="history-title">
        <p>
          <span>
            <History />
          </span>
          RECENT TRANSACTIONS
        </p>
      </div>
      <MainContainer className="trnasaction-main">
        <DateRow>
          <DateText>
            TODAY | {date.toString().slice(8, 11)} {date.toString().slice(4, 7)}
            ,{date.toString().slice(11, 16)}
          </DateText>
          <SelectBox>
            <option>TODAY</option>
            <option>YESTERDAY</option>
          </SelectBox>
        </DateRow>
        <Divider />
        <div className="transaction-container">
          {Transaction.transactions != null ? (
            Transaction.transactions.length != 0 ? (
              Transaction.transactions.map((item) => (
                <TXRow key={item.id} className="trxrow">
                  <TextContainer className="transction">
                    <TypeTx
                      className="icon"
                      receive={
                        item.from != wallet.wallet_address.toLowerCase()
                          ? true
                          : false
                      }
                    >
                      {item.from == wallet.wallet_address.toLowerCase()
                        ? "-"
                        : "+"}
                    </TypeTx>
                    <TypeAction>
                      <div className="sendrec">
                        {item.from == wallet.wallet_address.toLowerCase()
                          ? `Send ${item.tokenSymbol}`
                          : `Recive ${item.tokenSymbol}`}
                      </div>
                      <div className="hash">{item.hash}</div>
                    </TypeAction>
                  </TextContainer>
                  <AmountText className="amount">
                    <SvgContainer
                      receive={
                        item.from != wallet.wallet_address.toLowerCase()
                          ? true
                          : false
                      }
                    >
                      <ExpandMoreIcon />
                      <ExpandMoreIcon />
                    </SvgContainer>
                    <AmountTx>{fliterNumber(item.value)}</AmountTx>
                  </AmountText>
                </TXRow>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                Don't have any transaction
              </div>
            )
          ) : (
            <div>Loading....</div>
          )}
        </div>
      </MainContainer>
    </>
  );
};

export default Index;
