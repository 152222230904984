import { Link } from "react-router-dom";
import styled from "styled-components";
import Countdown from "react-countdown";

export const ButtonAgain = styled.button`
  background-color: #50cb93;
  color: #262626;
  padding: 16px 20px;
  width: 13rem;
  text-align: center;
  border-radius: 24px;
  outline: none;
  border: none;
`;

export const VerifyEmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #323232;
  font-family: "Microsoft YaHei" !important;
`;

export const VerifyEmailContainer = styled.div`
  width: 70%;
  height: 75%;
  background-color: #262626;
  border-radius: 24px;
  @media (max-width: 768px) {
    width: 90% !important;
  }
`;
export const VerifyContentColumn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 2rem;
`;

export const VerifyLogo = styled.img`
  width: 125px !important;
  border-radius: 1000px !important;
  border: 10px solid #323232;
  position: relative;
  bottom: 40px;
  @media (max-width: 768px) {
    width: 80px !important;
  }
`;

export const VerifyH1 = styled.h1`
  font-size: 40px;
  color: #ebd788;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const VerifyEmailInformation = styled.p`
  color: #50cb93;
  max-width: 500px;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const VerifyDescription = styled.p`
  color: #ebd788;
  max-width: 700px;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 15px;
    max-width: 500px;
  }
`;

export const SpanCountdown = styled.span`
  background-color: #ccc;
  color: #fff;
  padding: 16px 20px;
  width: 13rem;
  text-align: center;
  border-radius: 24px;
`;

export const VerifyTimer = styled(Countdown)`
  background-color: #ccc;
  color: #fff;
  padding: 16px 20px;
  width: 13rem;
  text-align: center;
  border-radius: 24px;

  @media (max-width: 768px) {
    width: 10rem;
    padding: 8px 20px;
  }
`;

export const VrifyContactUs = styled.div`
  color: #ebd788;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const ContactUsLink = styled(Link)`
  color: #ebd788;
  text-decoration: dashed;
`;

export const TextCenter = styled.div`
  text-align: center;
`;
