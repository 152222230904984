import styled from "styled-components";
export const MainContainer = styled.div`
  width: 80%;
  margin-bottom: 30px;
`;
export const DateRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;
export const SelectBox = styled.select`
  background-color: #5162bb;
  border-radius: 22px;
  padding: 0 5px;
  height: 33px;
  cursor: pointer;
  color: #ebecf1;
  font-size: 13px;
  text-align: center;
  font-family: "Microsoft YaHei" !important;
  font-weight: bold;
`;
export const DateText = styled.div`
  font-size: 15px;
  color: black;
  font-weight: bold;
  line-height: 26px;
`;
export const Divider = styled.div`
  height: 2px;
  background-color: #a8a0a0;
  width: 100%;
  margin: 20px 0;
`;
export const TXRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  height: 66px;
  position: relative;
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const TypeAction = styled.div`
  display: flex;
  flex-direction: column;
  div {
    &:first-child {
      color: #000;
      font-size: 19px;
      span {
        font-weight: bold;
        margin-left: 5px;
      }
    }
    &:last-child {
      font-size: 8px;
      font-weight: bold;
      color: #000;
      position: relative;
      top: 8px;
    }
  }
`;
export const TypeTx = styled.div`
  color: ${(props) => (props.receive ? "#6acc41" : "#f00")};
`;
export const AmountText = styled.div`
  display: flex;
`;
export const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    transform: ${(props) => (props.receive ? "unset" : "rotate(180deg)")};
    fill: ${(props) => (props.receive ? "#6acc41" : "#f00")};
    margin: -8px 0;
    position: relative;
    top: 16px;
  }
`;
export const AmountTx = styled.div`
  font-size: 15px;
  margin-top: 11px;
  letter-spacing: 2.06px;
  margin-right: 5px;
  font-weight: bold;
`;
