import { BaseUrl } from "./../apis/GlobalConfing";
const qr = require("querystring");

export const CreateUserWalletAddress = async (token) => {
  const response = await fetch(
    `${BaseUrl}/blockchain/newwallet`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      body: qr.stringify({token}),
    }
  );
  const data = await response.json();

  return data;
};

export const GetUserWalletInventory = async (wallet_address) => {
  const response = await fetch(`${BaseUrl}/blockchain/Getbalancewallet`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    body: qr.stringify({
      wallet: wallet_address,
      token: localStorage.getItem('token')
    })
  });
  const data = await response.json();
  return data;
};
