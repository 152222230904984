import {
  CreateUserWalletAddress,
  GetUserWalletInventory,
} from "./../../hooks/wallet";

export const CreateUserWalletAddressAction = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const {data} = await CreateUserWalletAddress(token);
    await dispatch({
      type: "GET_USER_WALLET_ADDRESS",
      payload: data.address,
    });
  };
};
export const GetUserWalletInventoryAction = (wallet_address) => {
  return async (dispatch) => {
    const {data} = await GetUserWalletInventory(wallet_address);
    await dispatch({ type: "GET_USER_INVENTORY", payload: data });
  };
};
