import { UseRegisterUser, UseVerifyEmailUser } from "../../hooks/register";
import {
  ConnectionError,
  ErrorMessage,
  SuccessMessage,
} from "../../utils/messages";

export const RegisterUserAction = (user_info, reset, history) => {
  return async () => {
    const response = await UseRegisterUser(user_info);
    switch (response.success) {
      case true:
        localStorage.setItem("v_t", response.token);
        reset();
        history.replace("/auth/verify-email");
        break;
      case false:
        ErrorMessage(response.data);
      default:
        ConnectionError();
    }
  };
};

export const VerifyEmailUserAction = (user_info, history) => {
  return async () => {
    const response = await UseVerifyEmailUser(user_info);
    switch (response.success) {
      case true:
        history.replace("/auth/verify-done");
        localStorage.clear();
        SuccessMessage("Successfully Registration");
        break;
      case false:
        ErrorMessage(response.data);
        break;
      default:
        ConnectionError();
    }
  };
};
