import React, { useState, useRef } from "react";
import "./home.css";
import Layout from "../../common/layout";
import methodesData from "./methodes/peyment-methods.data";
import Methode from "./methodes";
import Chats from "./profile/envelope";
import userimage from "../../../assets/userimage.png";
import { ReactComponent as BellIcon } from "../../../assets/bell.svg";
import { ReactComponent as EnvelopeIcon } from "../../../assets/envelope.svg";
import { ReactComponent as CalenderIcon } from "../../../assets/calendar.svg";
import PersonIcon from "@material-ui/icons/Person";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import CloseIcon from "@material-ui/icons/Close";
import HttpsIcon from "@material-ui/icons/Https";
import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import Grow from "@material-ui/core/Grow";
import Zoom from "@material-ui/core/Zoom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import logoImage from './img/logo.svg';
import CopyImage from './img/Icon feather-copy.svg';
import {BaseUrlImg} from '../../../apis/GlobalConfing'
import {
  ChangeEmailAction,
  ChangePasswordAction,
  ChangeUserNameAction,
  ChangeUserProfileAction,
  GetSeenLengthAction,
  GetUserInfoAction,
  VerrifyEmailAction,
} from "./../../../redux/auth/auth.action";
import ShowChats from "./profile/ShowChats";
import { chatAction, chatClose } from "../../../redux/chats/chatAction";
import "./profile/envelope/envelope.css";
import { Link } from "react-router-dom";
import "./profile/notification/notification.css";
import Notifies from "./profile/notification/notification";
import {
  CreateUserWalletAddressAction,
  GetUserWalletInventozryAction,
} from "../../../redux/wallet/wallet.action";
import { GetGenealogyListAction } from "../../../redux/genealogy/genealogy.action";
import { TwoAuthDialog } from "./TwoAuthDialog";
import { GetTicketListAction } from "../../../redux/tickets/ticket.action";
import {
  GetPlanListAction,
  GetPlanListHomeAction,
} from "../../../redux/plan/plan.action";
import { GetTransactionListAction } from "../../../redux/transaction/transaction.action";
import { GetNotificationListAction } from "../../../redux/notifications/notification.action";
import { TurnOffTwoAuthDialog } from "./TurnOffTwoAuthDialog";
import { ErrorMessage } from "../../../utils/messages";
import { SearchTransactionAction } from "./../../../redux/transaction/transaction.action";
import { Close } from "@material-ui/icons";
import { ConfirmTwoAuth } from "./ComplateTwoAuth";
import { toast } from 'material-react-toastify';
import { getDataOverview } from './../../../hooks/overview';

const Home = () => {
  const ShowMessage = useSelector((state) => state.showChat);
  const user_info = useSelector((state) => state.AuthenticationUser);
  const Genealogy = useSelector((state) => state.GenealogyUsers);
  const plans = useSelector((state) => state.Plan);
  const two_auth = useSelector((state) => state.TwoAuthInfo);
  const notification = useSelector((state) => state.Notification);
  const transaction = useSelector((state) => state.Transaction);
  const seens = useSelector((state) => state.Seens);

  const dispatch = useDispatch();
  const profileClasses = ["item"];
  const securityClasses = ["item"];
  const accountSettingClasses = ["item"];
  const lock = <HttpsIcon />;
  const [editProfile, setEditProfile] = useState(false);
  const [userData, setUserData] = useState({
    password: "12345678",
    image: false,
    imageSrc: false,
  });
  const [newPass, setNewPass] = useState(false);
  const [accountSettings, setAccountSettings] = useState(false);
  const [Authentication, setAuthentication] = useState(false);
  const [authDialog, setAuthDialog] = useState(false);
  const [turnOffAuthDialog, setTurnOffAuthDialog] = useState(false);
  const [security, setSecurity] = useState(false);
  const [show, setShow] = useState(true);
  const [showNotif, setShowNotif] = useState(false);
  const [chatShow, setChatShow] = useState(false);
  const [Notify, setNotify] = useState(false);
  const [overview, setOverview] = useState({});

  const [confirm, showConfirm] = useState(false);

  const [name, setNewName] = useState("");
  const [email, setEmail] = useState(false);
  const [verifyEmailSec, setVerifyEmailSec] = useState(false);
  const [verifyEmailCode, setVerifyEmailCode] = useState("");
  const [showError, setShowError] = useState(false);

  const [hash, setHash] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const successSearch = () => {
    setShowSearch(true);
  };

  const handleSearchHash = () => {
    dispatch(SearchTransactionAction(hash, successSearch));
  };

  const VerifyChangeEmail = () => {
    setVerifyEmailSec(true);
  };

  const closeEditProfile = () => {
    setEditProfile(false);
    setShow(true);
    setNewName("");
  };

  const pictureRef = useRef(null);
  const handleChangeProfile = () => {
    if (name) {
      dispatch(ChangeUserNameAction(name, closeEditProfile));
    }
    if (pictureRef.current.files[0]) {
      dispatch(ChangeUserProfileAction(pictureRef.current.files[0], user_info.id, closeEditProfile));
    }
  };


  const backbtn = () => {
    setEmail(false);
    setNewPass(false);
    setShow(true);
    setChatShow(false);
    setEditProfile(false);
    setAccountSettings(false);
    setSecurity(false);
    dispatch(chatClose());
    setAuthentication(false);
    setNotify(false);
    setVerifyEmailSec(false);
  };

  const backbtn2 = () => {
    setShow(false);
    setChatShow(true);
    setEditProfile(false);
    setAccountSettings(false);
    setSecurity(false);
    dispatch(chatClose());
  };
  const openEditProfile = () => {
    setShow(false);
    setEditProfile(true);
    setAccountSettings(false);
    setSecurity(false);
  };

  const openAccountSettings = () => {
    setShow(false);
    setEditProfile(false);
    setAccountSettings(true);
    setSecurity(false);
  };
  const openSecurity = () => {
    setShow(false);
    setEditProfile(false);
    setAccountSettings(false);
    setSecurity(true);
  };

  const openNotify = () => {
    setShow(false);
    setEditProfile(false);
    setAccountSettings(false);
    setSecurity(false);
    setNotify(true);
    setShowNotif(false);
  };
  const showNotify = () => {
    setShow(false);
    setEditProfile(false);
    setAccountSettings(false);
    setSecurity(false);
    setShowNotif(true);
    setNotify(false);
  };
  const backNotif = () => {
    setShow(false);
    setEditProfile(false);
    setAccountSettings(false);
    setSecurity(false);
    setNotify(true);
    setShowNotif(false);
  };

  const saveChangeAccountSetting = () => {
    const CheckValidEmailPass = () => {
      var result = null;
      const newEmail = email || user_info.email;
      const enterEmail = newEmail.toLowerCase();
      const isYahoo = enterEmail.search("yahoo");
      const isGmail = enterEmail.search("gmail");
      const isOutlook = enterEmail.search("outlook");
      const isHotmail = enterEmail.search("hotmail");
      if (
        isYahoo == -1 &&
        isGmail == -1 &&
        isOutlook == -1 &&
        isHotmail == -1
      ) {
        result = false;
      } else {
        result = true;
      }
      return result;
    };
    const validEmailType = CheckValidEmailPass();
    if (
      newPass !== false && newPass !== '12345678' &&
      email !== false &&
      validEmailType == true
    ) {
      dispatch(ChangePasswordAction(newPass));
      setTimeout(() => {
        dispatch(ChangeEmailAction(email, VerifyChangeEmail));
      }, 2000);
    } else if (email !== false && validEmailType == true) {
      dispatch(ChangeEmailAction(email, VerifyChangeEmail));
    } else if (newPass !== false && newPass !== '12345678') {
      dispatch(ChangePasswordAction(newPass));
    } else {
      setShowError(true);
    }
  };
  const VerifyChangeEmailCode = () => {
    if (verifyEmailCode != "") {
      dispatch(VerrifyEmailAction(verifyEmailCode, backbtn));
    } else {
      ErrorMessage("Please Enter Verify Code");
    }
  };

  // profile input handlers
  const passwordChangeHandler = (e) => {
    setNewPass(e.target.value);
  };

  // show chats
  const showChatHandler = () => {
    setShow(false);
    setChatShow(true);
  };
  const matches = useMediaQuery("(min-width: 768px)");

  const hashCopy = useRef(null);

  const copyHash = () => {
    navigator.clipboard.writeText(hashCopy.current.innerText);
    toast.success('Contract Address Success Copied');
  }

  React.useEffect(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(GetUserInfoAction());
      dispatch(CreateUserWalletAddressAction());
      dispatch(GetGenealogyListAction());
      dispatch(GetTicketListAction());
      dispatch(GetNotificationListAction());
      dispatch(GetSeenLengthAction());
      setOverview(await getDataOverview());
    }
  }, []);
  return (
    <Layout>
      <div className="mainContainer home">
        <div className="center-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search in Transactions..."
              autoComplete="false"
              value={hash}
              onChange={(e) => setHash(e.target.value)}
            />
            {showSearch == false ? (
              <span onClick={handleSearchHash} style={{ cursor: "pointer" }}>
                <SearchOutlinedIcon />
              </span>
            ) : (
              <span
                onClick={() => {
                  setShowSearch(false);
                  setHash("");
                }}
                style={{ cursor: "pointer" }}
              >
                <Close />
              </span>
            )}
          </div>
          {showSearch == true ? (
            <div className="transaction-overview">
              <h3>
                Hash:
                <span>
                  {transaction.search != null ? transaction.search.hash_tx : ""}
                </span>
              </h3>
              <h3>
                From:
                <span>
                  {transaction.search != null ? transaction.search.from : ""}
                </span>
              </h3>
              <h3>
                To:
                <span>
                  {transaction.search != null ? transaction.search.to : ""}
                </span>
              </h3>
              <h3>
                Amount:
                <span>
                  {transaction.search != null ? transaction.search.amount : ""}
                </span>
              </h3>
              <div className="date">
                <span className="title-date">Date</span>
                <span>
                  {transaction.search != null
                    ? transaction.search.createdAt.toString().slice(0, 10)
                    : ""}
                </span>
                <span className="title-date">Tilme</span>
                <span>
                  {transaction.search != null
                    ? transaction.search.createdAt.toString().slice(11, 16)
                    : ""}
                </span>
              </div>
            </div>
          ) : (
            <div className="payment-methodes">
                <div>
                    <h2>Overview</h2>
                    <img src={logoImage} alt="logo" />
                </div>
                <div>
                    <div>
                        <div className="address">
                            <button>Contract Address</button>
                            <span>
                                <p ref={hashCopy}>{overview.smartcontract}</p>
                                <button className="icon-copy" onClick={copyHash}>
                                    <img src={CopyImage} alt="copy" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="box-items">
                        <div className="item">
                            Total Supply
                            <span>{overview.TotalSupply}</span>
                        </div>
                        <div className="item">
                            Holder
                            <span>{overview.Holder}</span>
                        </div>
                        <div className="item">
                            Transfers
                            <span>{overview.Transfer}</span>
                        </div>
                        <div className="btns">
                            <button className="btn-yellow">
                                BEP-20 Token
                            </button>
                            <a className="btn-black" href="https://bscscan.com/token/0x4a6be2e0a65dda5dc13608cd28cc2262f6a2244e" target="_blank">
                                Wiew on Bsccan
                            </a>
                        </div>
                    </div>
                </div>
            </div>
          )}

          <div className="income-overview">
            <TradingViewWidget
              symbol="BNBUSDT"
              theme={Themes.light}
              locale="us"
              autosize
            />
          </div>
          <div className="yourLink">
            <div className="textContainer ">
              <div className="caption">
                <p>
                  Your Referral Link : https://stake.cyruscoin.io?ref=
                  {user_info.reffral}
                </p>
                <p>Your Code :{user_info.reffral}</p>
              </div>
              <div className="circleTitle">
                <p>
                  {Genealogy.GenealogyUsers != null
                    ? Genealogy.GenealogyUsers.length
                    : "0"}
                </p>
                <p>Invited Friends</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div
            className="profile-container "
            style={{
              height:
                Notify || chatShow || showNotif
                  ? "100%"
                  : `${Authentication ? "100%" : "550px"}`,
              transition: "all 1s ease",

              marginBottom: !show ? "20px" : "0px",
            }}
          >
            <span id="back-to-top-anchor" />
            {/* top area == avatar , name */}
            <div
              style={{
                flexDirection: ShowMessage ? "row" : "column",
              }}
              className="top-area"
            >
              <div
                style={{
                  height: ShowMessage ? "80px" : "110px",
                  width: ShowMessage ? "80px" : "110px",
                  display: Authentication ? "none" : "flex",
                }}
                className="image-container"
              >
                <img src={`${BaseUrlImg}/${user_info.picname}`} alt="user Image" />
              </div>
              <p
                style={{
                  display: ShowMessage
                    ? "none"
                    : `${Authentication ? "none" : "block"}`,
                }}
              >
                {user_info.name}
              </p>
              <div
                style={{
                  display: ShowMessage ? "inline" : "none",
                }}
              >
                <Grow
                  in={ShowMessage}
                  {...(ShowMessage ? { timeout: 1000 } : {})}
                >
                  <div className="closebtnShowchat" onClick={() => backbtn2()}>
                    <CloseIcon style={{ color: "white" }} />
                  </div>
                </Grow>
              </div>
            </div>
            {/* icon bar */}
            <Collapse
              in={show}
              style={{
                transformOrigin: "center",
                minWidth: "100%",
                opacity: show ? "1" : "0",
              }}
              {...(show ? { timeout: 1000 } : {})}
            >
              <div className="icon-bar">
                <Badge
                  badgeContent={seens.noti}
                  overlap="circular"
                  max="99"
                  color="secondary"
                >
                  <div className="item">
                    <BellIcon onClick={() => openNotify()} />
                  </div>
                </Badge>
                <Badge
                  badgeContent={seens.ticket}
                  overlap="circular"
                  max="99"
                  color="secondary"
                >
                  <div className="item" onClick={() => showChatHandler()}>
                    <EnvelopeIcon />
                  </div>
                </Badge>

                <div className="item">
                  <CalenderIcon />
                </div>
              </div>
              {/* switch bar */}
              <div className="switch-bar">
                <p className="item active">Profile</p>
                <p className="item disabeld">
                  History<span className="lock">{lock}</span>
                </p>
              </div>
            </Collapse>
            {/* buttom area == edit , setting , security  */}
            <div className="buttom-area">
              <Collapse
                in={show}
                style={{
                  transformOrigin: "center",
                  minWidth: "100%",
                  opacity: show ? "1" : "0",
                }}
                {...(show ? { timeout: 1000 } : {})}
              >
                <div
                  className={profileClasses}
                  onClick={() => openEditProfile()}
                >
                  <p>Edit Profile</p>
                  <span>
                    <PersonIcon />
                  </span>
                </div>
                <div
                  className={accountSettingClasses}
                  onClick={() => openAccountSettings()}
                >
                  <p>Account Settings</p>
                  <span>
                    <SettingsOutlinedIcon />
                  </span>
                </div>
                <div className={securityClasses} onClick={() => openSecurity()}>
                  <p>Security</p>
                  <span>
                    <SecurityOutlinedIcon />
                  </span>
                </div>
              </Collapse>
              {/* Edit Profile */}

              <Zoom
                in={editProfile}
                style={{
                  transformOrigin: "center",
                  height: editProfile ? "100%" : "0",
                  opacity: editProfile ? "1" : "0",
                }}
                {...(editProfile ? { timeout: 1000 } : {})}
              >
                <div id="edit-profile" className="edit-profile">
                  <div className="item">
                    <input
                      type="text"
                      placeholder="Change NickName"
                      onChange={(e) => setNewName(e.target.value)}
                    />
                  </div>
                  <div className="item">
                    <input
                      type="file"
                      name="uploadfile"
                      id="img"
                      style={{ display: "none" }}
                      ref={pictureRef}
                    />
                    <label for="img">Change Profile Picture</label>
                  </div>
                  <div className="item action">
                    <div className="button-container">
                      <button className="reset">reset</button>
                      <button className="save" onClick={handleChangeProfile}>
                        save
                      </button>
                    </div>
                  </div>
                </div>
              </Zoom>
              {/* Account Settings */}
              <Zoom
                in={accountSettings}
                style={{
                  transformOrigin: "center",
                  height: accountSettings ? "100%" : "0",
                  opacity: accountSettings ? "100%" : "0",
                }}
                {...(accountSettings ? { timeout: 1000 } : {})}
              >
                <div id="account-setting" className="account-setting">
                  {verifyEmailSec == false ? (
                    <>
                      {" "}
                      <div className="item">
                        <p>Change Password</p>
                        <div className="input ">
                          <input
                            type="password"
                            id="pass"
                            onChange={(e) => passwordChangeHandler(e)}
                            value={
                              newPass !== false ? newPass : userData.password
                            }
                          />
                        </div>
                      </div>
                      <div className="item">
                        <p>Change Email</p>
                        <div className="input" id="email">
                          <input
                            type="email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setShowError(false);
                            }}
                            value={email !== false ? email : user_info.email}
                          />
                          {showError == true ? (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              Email is not valid!
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="item">
                      <p>Verify Code</p>
                      <div className="input ">
                        <input
                          type="text"
                          id="pass"
                          onChange={(e) => setVerifyEmailCode(e.target.value)}
                          value={verifyEmailCode}
                          autoComplete="false"
                        />
                      </div>
                    </div>
                  )}
                  <div className="item action">
                    <div className="button-container">
                      <button className="reset">reset</button>
                      <button
                        className="save"
                        onClick={
                          verifyEmailSec == false
                            ? saveChangeAccountSetting
                            : VerifyChangeEmailCode
                        }
                      >
                        save
                      </button>
                    </div>
                  </div>
                </div>
              </Zoom>
              {/* Security */}
              <Zoom
                in={security}
                style={{
                  transformOrigin: "center",
                  height: security ? "100%" : "0",
                  opacity: security ? "100%" : "0",
                  display: Authentication ? "none" : "block",
                }}
                {...(security ? { timeout: 1000 } : {})}
              >
                <div id="security" className="security">
                  <div className="item">
                    <p
                      onClick={() =>
                        user_info.Authenticator == false
                          ? setAuthDialog(true)
                          : setTurnOffAuthDialog(true)
                      }
                    >
                      Two-Facktor Authentication{" "}
                      {user_info.Authenticator == true ? (
                        <span style={{ position: "absolute", right: "10px" }}>
                          ON
                        </span>
                      ) : (
                        <span style={{ position: "absolute", right: "10px" }}>
                          OFF
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </Zoom>

              <Zoom in={showNotif} {...(showNotif ? { timeout: 1000 } : {})}>
                <div
                  className="closebtn"
                  style={
                    {
                      // display: ShowMessage ? 'none' : 'iline',
                    }
                  }
                >
                  <CloseIcon
                    style={{ color: "white" }}
                    onClick={() => backNotif()}
                  />
                </div>
              </Zoom>
              <TwoAuthDialog
                open={authDialog}
                close={() => setAuthDialog(false)}
                success={() => setAuthentication(true)}
              />
              <TurnOffTwoAuthDialog
                open={turnOffAuthDialog}
                close={() => setTurnOffAuthDialog(false)}
                success={() => {
                  setTurnOffAuthDialog(false);
                  backbtn();
                }}
              />
              <ConfirmTwoAuth
                open={confirm}
                close={() => showConfirm(false)}
                success={() => {
                  showConfirm(false);
                  backbtn();
                }}
              />
              <Zoom
                in={Authentication}
                {...(Authentication ? { timeout: 1000 } : {})}
                style={{
                  display: Authentication ? "flex" : "none",
                }}
              >
                <div className="Authentication-container">
                  <div className="qr-code">
                    <Link
                      rel="nofollow"
                      href="https://www.qr-code-generator.com"
                      style={{ cursor: "default", border: "0" }}
                    >
                      <img src={two_auth.qrcode} alt="" />
                    </Link>
                    <div className="sybtitle-qr">
                      <p align="center">Get codes grom an authenticator app</p>
                    </div>
                  </div>
                  <div align="center" className="Authentication-description">
                    <p>
                      Scan QR CODE below or manually type the secret key into
                      your Authentication app{" "}
                    </p>
                    <h3>
                      Code:
                      <br />
                      <span>{two_auth.secret}</span>
                    </h3>
                  </div>
                  <button className="confirmBtn" onClick={showConfirm}>
                    Confirm
                  </button>
                </div>
              </Zoom>
            </div>
            <Zoom
              in={chatShow}
              style={{
                transformOrigin: "center",
                height: chatShow ? "600px" : "0",
                opacity: chatShow ? "1" : "0",
                display: ShowMessage ? "none" : "inline",
                overflowY: "scroll",
              }}
              {...(chatShow ? { timeout: 1000 } : {})}
            >
              <div id="chats" className="chats">
                {ShowMessage == true ? <ShowChats /> : <Chats />}
              </div>
            </Zoom>
            <Zoom
              in={ShowMessage}
              style={{
                height: ShowMessage ? "100%" : "-100%",
                display: ShowMessage ? "block" : "none",
                width: ShowMessage ? "100%" : "-100%",
              }}
              {...(ShowMessage ? { timeout: 1000 } : {})}
            >
              <div id="ShowChats">
                <ShowChats />
              </div>
            </Zoom>

            <Grow
              style={{
                display: !Notify ? "none" : "inline",
              }}
              in={Notify}
              {...(Notify ? { timeout: 1000 } : {})}
            >
              <div className="top-icon">
                <Badge
                  badgeContent={seens.noti}
                  overlap="circular"
                  max="99"
                  color="secondary"
                >
                  <div className="icon-item">
                    <BellIcon />
                  </div>
                </Badge>
              </div>
            </Grow>
            <Zoom in={Notify} {...(Notify ? { timeout: 1000 } : {})}>
              <div
                className="notifies"
                style={{
                  display: Notify ? "block" : "none",
                  height: "600px",
                }}
              >
                <Notifies click={() => showNotify()} />
              </div>
            </Zoom>
            {/* Notification */}
            <Zoom
              in={showNotif}
              style={{
                transformOrigin: "center",
                height: showNotif ? "100%" : "0",
                opacity: showNotif ? "100%" : "0",
                display: !showNotif ? "none" : "block",
              }}
              {...(showNotif ? { timeout: 1000 } : {})}
            >
              <div
                className="notification-item"
                style={{ position: "relative" }}
              >
                <div>
                  <h2>
                    {notification.notif_text != null ? (
                      notification.notif_text.title
                    ) : (
                      <div>Loading...</div>
                    )}
                  </h2>
                  <hr />
                  <p>
                    {notification.notif_text != null ? (
                      notification.notif_text.message
                    ) : (
                      <div>Loading...</div>
                    )}
                  </p>
                </div>
                <p style={{ position: "absolute", bottom: "2px" }}>
                  {notification.notif_text != null ? (
                    notification.notif_text.data.toString().slice(0, 10)
                  ) : (
                    <div>Loading...</div>
                  )}
                </p>
              </div>
            </Zoom>

            <div
              style={{
                display: ShowMessage ? "none" : "inline",
              }}
            >
              <Zoom in={chatShow} {...(chatShow ? { timeout: 1000 } : {})}>
                <div className="closebtn" onClick={() => backbtn()}>
                  <CloseIcon style={{ color: "white" }} />
                </div>
              </Zoom>

              <Zoom in={Notify} {...(Notify ? { timeout: 1000 } : {})}>
                <div className="closebtn" onClick={() => backbtn()}>
                  <CloseIcon style={{ color: "white" }} />
                </div>
              </Zoom>
              {/* Close Edit Profile */}
              <Zoom
                in={editProfile}
                {...(editProfile ? { timeout: 1000 } : {})}
              >
                <div className="closebtn" onClick={() => backbtn()}>
                  <CloseIcon style={{ color: "white" }} />
                </div>
              </Zoom>
              {/* Close Edit Profile */}
              <Zoom
                in={accountSettings}
                {...(accountSettings ? { timeout: 1000 } : {})}
              >
                <div className="closebtn" onClick={() => backbtn()}>
                  <CloseIcon
                    style={{ color: "white" }}
                    onClick={() => backbtn()}
                  />
                </div>
              </Zoom>
              {/* Close Edit Sequrity */}
              <Zoom in={security} {...(security ? { timeout: 1000 } : {})}>
                <div className="closebtn" onClick={() => backbtn()}>
                  <CloseIcon style={{ color: "white" }} />
                </div>
              </Zoom>
            </div>

            <Grow
              style={{
                display: ShowMessage ? "none" : "inline",
              }}
              in={chatShow}
              {...(chatShow ? { timeout: 1000 } : {})}
            >
              <div className="top-icon">
                <Badge
                  badgeContent={seens.ticket}
                  overlap="circular"
                  max="99"
                  color="secondary"
                >
                  <div className="icon-item" onClick={() => showChatHandler()}>
                    <EnvelopeIcon />
                  </div>
                </Badge>
              </div>
            </Grow>
            <Grow
              style={{
                display: ShowMessage ? "none" : "inline",
              }}
              in={showNotif}
              {...(chatShow ? { timeout: 1000 } : {})}
            >
              <div className="top-icon">
                <Badge
                  badgeContent={seens.noti}
                  overlap="circular"
                  max="99"
                  color="secondary"
                >
                  <div className="icon-item" onClick={() => showChatHandler()}>
                    <BellIcon />
                  </div>
                </Badge>
              </div>
            </Grow>
          </div>
          <Collapse
            in={show}
            style={{ minWidth: show ? "100%" : "100%" }}
            {...(show ? { timeout: 1000 } : {})}
          >
            <div className="other-info-container">
              <div className="info-item">
                <div className="circle"></div>
                <div className="circle2">
                  <h5>$</h5>
                </div>
                <p>Stacking Profit</p>
                <h5>
                  {" "}
                  {plans.plan_list_home != null
                    ? plans.plan_list_home.total_profit.toFixed(2)
                    : ""}
                </h5>
              </div>
              <div className="info-item">
                <div className="circle"></div>
                <div className="circle2">
                  <h5>$</h5>
                </div>
                <p>Referral Profit</p>
                <h5>
                  {plans.plan_list_home != null
                    ? plans.plan_list_home.totalrefrall
                    : ""}
                </h5>
              </div>
              <div className="info-item ">
                <div className="circle"></div>
                <div className="circle2">
                  <h5>$</h5>
                </div>
                <h5>
                  {" "}
                  {plans.plan_list_home != null
                    ? plans.plan_list_home.totalday
                    : ""}
                </h5>
                <p>Number of Days Past</p>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
