import React, { useEffect } from "react";
import Layout from "../../common/layout";
import "./document.css";
import { useDispatch } from 'react-redux';
import { getInitDocument } from './../../../redux/documnet/document.action';
import { useSelector } from 'react-redux';

const Ducument = () => {
  const dispatch = useDispatch();
  const dataDocument = useSelector(state => state.document);
  useEffect(() => {
    dispatch(getInitDocument());
  }, []);
  return (
    <Layout>
      <span id="back-to-top-anchor" />
      <div className="center-container">

        <p className="title"> Document </p>

        <section className="documents documents-res">
          {dataDocument.map(item => (
            <a href={`https://${item.link}`} target="_blank" className="document" key={item}>
              <img src={`${item.picture}`} alt={item.name}/>
              <h3>{item.name}</h3>
            </a>
          ))}
        </section>
      </div>
    </Layout>
  );
};

export default Ducument;