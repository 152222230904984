import React from 'react'
import "./addplan.css"
const index = ({onClick}) => {
    return (
        <div className="add-plan">
           <p onClick={onClick}>Add plan</p> 
        </div>
    )
}

export default index
