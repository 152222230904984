import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "../components/pages/home";
import AboutUs from "../components/pages/aboutUs";
import ContactUs from "../components/pages/contactus";
import Genealogy from "../components/pages/genealogy";
import Document from "../components/pages/document";
import Wallet from "../components/pages/wallet";
import Exchange from "../components/pages/exchange";
import Logout from "./../components/pages/auth/logout/Logout";
import VerifyEmail from "../components/pages/auth/verify/verifyEmail";
import VerifySucess from "../components/pages/auth/verify/verifysucess";
import VerifyTwoAuth from "../components/pages/auth/verify/TwoAuth";
import ForgetPassword from "../components/pages/auth/verify/ForgetPassword";
import DesktopMain from "../components/pages/auth/main/DesktopMain";

const Routes = () => {
  const token = localStorage.getItem("token");

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            path="/home"
            exact
            render={() => (token ? <Home /> : <Redirect to="/auth" />)}
          />
          <Route path={["/auth", "/"]} exact component={DesktopMain} />
          <Route path="/auth/verify-email" exact component={VerifyEmail} />
          <Route path="/auth/verify-done" exact component={VerifySucess} />
          <Route path="/auth/two-auth" exact component={VerifyTwoAuth} />
          <Route
            path="/auth/forget-password"
            exact
            component={ForgetPassword}
          />
          <Route path="/exit" exact component={Logout} />

          <Route
            path="/genealogy"
            render={() => (token ? <Genealogy /> : <Redirect to="/auth" />)}
          />
          <Route
            path="/document"
            render={() => (token ? <Document /> : <Redirect to="/auth" />)}
          />
          <Route
            path="/exchange"
            render={() => (token ? <Exchange /> : <Redirect to="/auth" />)}
          />
          <Route
            path="/wallet"
            render={() => (token ? <Wallet /> : <Redirect to="/auth" />)}
          />
          <Route
            path="/contact-us"
            render={() => (token ? <ContactUs /> : <Redirect to="/auth" />)}
          />
          <Route
            path="/about-us"
            render={() => (token ? <AboutUs /> : <Redirect to="/auth" />)}
          />
          <Route render={() => (<Redirect to="/" />)} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
