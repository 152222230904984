import React, { useState } from "react";
import Input from "./Input";
import "./transfer.css";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ReactComponent as BNBIcon } from "../../../../assets/Binance-BNB-Icon-Logo.wine.svg";
import { SelectBox } from "./../historyTitle/history.styled";
import { useDispatch, useSelector } from "react-redux";
import { ChangeCoinRequestTransactionAction } from "../../../../redux/transaction/transaction.action";
import Logo from "./img/checkmark.png";
import { SuccessMessage, ErrorMessage } from "../../../../utils/messages";
const qr = require("querystring");
import { BaseUrl } from "../../../../apis/GlobalConfing";

const Index = ({ goBack, goNext, step }) => {
  const [form, setForm] = useState({
    coin: "BNB",
    amount: "",
    coin_amount: "",
    recive_amount: 0,
    cyrus_coin: 0,
  });

  const dispatch = useDispatch();

  const transaction = useSelector((state) => state.Transaction);
  const wallet = useSelector((state) => state.Wallet);
  const loading = useSelector((state) => state.loading);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    const info = {
      coin: form.coin.toUpperCase(),
      amount: form.amount,
      token,
    };
    if (+info.amount != "") {
      dispatch({ type: "LOADING", payload: true });
      dispatch(ChangeCoinRequestTransactionAction(info, goNext));
    } else {
      ErrorMessage("The value should not be empty");
    }
  };

  const CheckWallet = () => {
    if (form.coin == "BNB") {
      return wallet.inventory != null ? wallet.inventory.balance_bnb : "0";
    } else if (form.coin == "ADA") {
      return wallet.inventory != null ? wallet.inventory.balance_ada : "0";
    } else if (form.coin == "USDT") {
      return wallet.inventory != null ? wallet.inventory.balance_usdt : "0";
    }
  };
  var checked_coin = "";
  React.useEffect(async () => {
    checked_coin = CheckWallet();
    if (form.amount.length != 0) {
      const response = await fetch(`${BaseUrl}/blockchain/Priceconversion`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body: qr.stringify({
          amount: form.amount,
          namecoin: form.coin,
        }),
      });
      const data = await response.json();
      setForm((prev) => ({
        ...prev,
        recive_amount: data.recive,
        cyrus_coin: data.cyrusprice,
      }));
    }
  }, [checked_coin, form.amount]);

  const Copy = () => {
    var copyText = document.getElementById("hash_receipt");
    navigator.clipboard.writeText(copyText.innerHTML);
    SuccessMessage("Hash Success Copied");
  };

  return (
    <div className="transfer">
      <div className="box">
        {step === 5 ? (
          <div className="">
            <Input
              type="number"
              id="spend"
              label="Spend"
              placeholder="ex: 0.029619 - 5.923725"
              name="amount"
              onChange={handleChange}
              defaultValue={checked_coin}
              style={{ color: "#000" }}
            >
              <select
                className="coin-select"
                name="coin"
                onChange={handleChange}
              >
                <option value="BNB">BNB</option>
                <option value="ADA">ADA</option>
                <option value="USDT">USDT</option>
              </select>
            </Input>

            <Input
              type="number"
              id="receive"
              label="Receive"
              placeholder="0.00"
              tooltip={`1 CYR = ${form.cyrus_coin}`}
              value={form.recive_amount}
              style={{ color: "gray" }}
            >
              <BNBIcon />
              <span>CYR</span>
            </Input>
          </div>
        ) : (
          <div className="confirm-step">
            <div className="desc">
              <p className="text-center">
                The purchase was successful, the amount sent was{" "}
                <span className="mx-2">
                  {" "}
                  {transaction.receipt_change != null
                    ? ` ${transaction.receipt_change.coin_amount} `
                    : "0"}
                </span>{" "}
                <span className="mx-2">
                  {" "}
                  {transaction.receipt_change != null
                    ? ` ${transaction.receipt_change.coin_send_name} `
                    : ""}{" "}
                </span>
                and the amount received was{" "}
                <span className="mx-2">
                  {transaction.receipt_change != null
                    ? ` ${transaction.receipt_change.cyrus_recive} `
                    : "0"}
                </span>{" "}
                CYR added to your account.
              </p>
            </div>

            <div className="token">
              <div className="logo"></div>

              <div className="token-box">
                <span id="hash_receipt">
                  {transaction.receipt_change != null
                    ? transaction.receipt_change.hash
                    : ""}
                </span>
              </div>

              <div className="copy">
                <button type="button" onClick={Copy}>
                  <FileCopyOutlinedIcon />
                </button>
                <span>Copy</span>
              </div>
            </div>
          </div>
        )}

        <div className="btn-group">
          <button type="button" onClick={goBack}>
            Back
          </button>
          {step === 5 ? (
            <button type="button" onClick={handleSubmit} disabled={loading}>
              {step === "4" ? "Continue" : "Confirm Transaction"}
              {loading ? <div className="spinner-border"></div> : null}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Index;
