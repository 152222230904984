import styled from "styled-components";

export const ShowChatContainer = styled.div`
  width: 100%;
  height: fit-content;
  box-shadow: inset 1px 2px 19px 0px #00000052;
  border-radius: 25px;
`;

export const NameText = styled.div`
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  left: 10px;
`;
export const Messages = styled.div``;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: #b3bdef;
  border-radius: 5px;
  color: #3c4572;
  padding: 20px;
`;

export const InputField = styled.div`
  width: 100%;
  height: 35px;
  position: fixed;
  bottom: 0;
`;

export const ShowChatContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  border-radius: 17px;
  width: 100%;
  overflow-y: scroll;
  position: relative;
`;
export const RecivedMessage = styled.div`
  padding: 15px;
  background-color: #9fa9f8;
  color: #fff;
  max-width: 55vw;
  width: 200px;
  border-radius: 10px;
  font-size: 14px;
  margin: 15px;
  margin-top: 5px;
  font-weight: 600;
`;
export const RecivedMessageLabel = styled.div`
  max-width: 55vw;
  width: max-content;
  border-radius: 10px;
  font-size: 14px;
  margin-left: 18px;
  margin-top: 15px;
  font-weight: 600;
  color: #9fa9f8;
`;

export const Sendmessage = styled.div`
  padding: 15px;
  background-color: #fff;
  color: #9fa9f8;
  width: 200px;
  border-radius: 10px;
  font-size: 14px;
  margin: 15px;
  margin-top: 5px;
  font-weight: 600;
  position: relative;
`;
export const SendmessageLabel = styled.div`
  width: max-content;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 18px;
  margin-top: 15px;
  font-weight: 600;
  color: #9fa9f8;
`;

export const AlignLeft = styled.div`
  margin-top: 15px;
`;
export const AlignRight = styled.div`
  margin-top: 15px;
`;
