import { GetGenealogyList } from "../../hooks/genealogy";

export const GetGenealogyListAction = () => {
  return async (dispatch) => {
    const response = await GetGenealogyList();

    await dispatch({
      type: "GET_GENEALOGY_USERS_LIST",
      payload: response.ref,
    });
  };
};
