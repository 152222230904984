const InitState = {
  ticket_list: null,
  ticket_messages: [],
  selected_ticket: null,
  active_ticket: null,
};

export const TicketReducer = (state = InitState, action) => {
  switch (action.type) {
    case "GET_TICKET_LIST":
      return { ...state, ticket_list: action.payload };
    case "GET_TICKET_MSG":
      return { ...state, ticket_messages: action.payload };
    case "GET_TICKET_INFO":
      return { ...state, selected_ticket: action.payload };
    case "GET_AC_TICKET":
      return { ...state, active_ticket: action.payload };
    default:
      return state;
  }
};
