import React, { useEffect, useState } from "react";
import ArrowBack from "@material-ui/icons/ArrowBack";
import LockIcon from "@material-ui/icons/Lock";
import "./addWallet.css";
import data from "./data";
import CloseIcon from "@material-ui/icons/Close";

const index = ({ goBack, goNext }) => {
  const [width, setWidth] = useState(1024);

  const handleClick = (item) => {
    if (!item.disabled) {
      goNext();
    }
  };

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [width]);

  return (
    <>
      {width > 768 ? (
        <div className="add-wallet">
          {data.map((item, i) => (
            <div key={i} className="item" onClick={() => handleClick(item)}>
              <span className={item.disabled ? "disabled" : ""}>
                {item.title}
                {item.disabled && <LockIcon style={{ margin: "0 0 0 12px" }} />}
              </span>
            </div>
          ))}

          <button className="back" type="button" onClick={goBack}>
            <ArrowBack />
          </button>
        </div>
      ) : (
        <>
          <div className="mobile-add-wallet-container">
            {data.map((item, i) => (
              <div
                key={i}
                className="mobile-item"
                onClick={() => handleClick(item)}
              >
                <span className={item.disabled ? "disabled" : ""}>
                  {item.title}
                  {item.disabled && (
                    <LockIcon style={{ margin: "0 0 0 12px", color: "#ccc" }} />
                  )}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default index;
