import { BaseUrl } from "../apis/GlobalConfing";
const qr = require("querystring");

export const GetGenealogyList = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(`${BaseUrl}/user/reflist`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    body: qr.stringify({token}),
  });
  const data = await response.json();
  return data;
};
