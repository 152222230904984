import React, { useRef, useState } from "react";
import "./send.css";
import MainButton from "./../../../common/button/index";
import Receipt from "./../recipt";
import { useDispatch, useSelector } from "react-redux";
import { SendRequestTransactionAction } from "../../../../redux/transaction/transaction.action";
import { POSTamountToUsd } from "./../../../../hooks/transaction";

const Index = () => {
  const [zIndex1, setZIndex1] = useState(0);
  const [zIndex2, setZIndex2] = useState(0);

  const [btnLoading, setBtnLoading] = useState(false);

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    amount: 0,
    to: "",
  });

  const transaction = useSelector((state) => state.Transaction);
  const wallet = useSelector((state) => state.Wallet);

  const setUSD = useRef(null);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const { data } = await POSTamountToUsd(value || 0);
    setUSD.current.innerHTML = data.msg;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const ChangeBtnText = (status) => {
    setBtnLoading(status);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    const transaction_info = {
      token,
      amount: form.amount,
      to: form.to,
    };
    dispatch(
      SendRequestTransactionAction(
        transaction_info,
        ChangeBtnText,
        wallet.wallet_address
      )
    );
    ChangeBtnText(true);
  };

  const shortedBalance = (value) => {
    if (wallet.wallet_address && value != undefined) {
      const findedIndex = value.toString().indexOf(".");
      const new_balance = value.toString().slice(0, findedIndex + 5);

      return new_balance;
    }
  };

  return (
    // <div className="send-manage">
    <>
      {transaction.show_receipt === false ? (
        <>
          <div className="content" style={{ gap: "50px" }}>
            <div className="item-container">
              <div
                id="cyrus"
                className="item1 item-style"
                style={{ zIndex: zIndex2 }}
              >
                <input
                  type="number"
                  placeholder="0"
                  onFocus={() => setZIndex2(10)}
                  onBlur={() => setZIndex2(0)}
                  name="amount"
                  onChange={handleChange}
                />{" "}
                <p>
                  of{" "}
                  <span className="mx-1">
                    {wallet.inventory != null
                      ? shortedBalance(wallet.inventory.balance_cyr)
                      : "0"}
                  </span>{" "}
                  CYRUS
                </p>
              </div>
              <div id="usd" className="item2">
                <p ref={setUSD}>0</p>
                <p>USD</p>
              </div>
            </div>
            <div
              className="item-container"
              style={{ maxWidth: "80%", margin: "auto" }}
            >
              <div id="address" className="item1" style={{ zIndex: zIndex1 }}>
                <input
                  placeholder="ADDRESS"
                  type="text"
                  onFocus={() => setZIndex1(10)}
                  onBlur={() => setZIndex1(0)}
                  name="to"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="item-container">
              <div id="netFee" className="item1">
                <p>NETWORK Fee</p>
              </div>
              <div id="free" className="item2">
                <p>0.0004</p>
              </div>
            </div>
          </div>
          <div className="sendbtn-container" style={{ marginTop: "30px" }}>
            <MainButton btnLoading={btnLoading} clicked={handleSubmit} />
          </div>
        </>
      ) : (
        <Receipt />
      )}
    </>
  );
};

export default Index;
