//import React from "react";
import React, { useState } from "react";
import "./receive.css";
import QRCode from "qrcode";
import qrcode from "../../../../assets/qrcode.png";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { useSelector } from "react-redux";
//import { useState } from "react-redux";
import { SuccessMessage } from "../../../../utils/messages";

const Index = () => {
  const wallet = useSelector((state) => state.Wallet);
  const [qrCode2, setQrCode] = useState("");
  const Copy = () => {
    var copyText = document.getElementById("wallet");
    navigator.clipboard.writeText(copyText.innerHTML);
    SuccessMessage("Wallet Address Success Copied");
  };

  QRCode.toDataURL(wallet.wallet_address)
    .then((url) => {
      setQrCode(url);
    })
    .catch((err) => {
      console.error(err);
    });
  return (
    <>
      {/* <div className="recive-manager"> */}
      <img src={qrCode2} alt="qrcode" />
      <div className="code">
        <p id="wallet">
          {wallet.wallet_address != null ? wallet.wallet_address : "loading..."}
        </p>
      </div>
      <div
        className="copy-container"
        onClick={Copy}
        style={{ cursor: "pointer" }}
      >
        <FileCopyOutlinedIcon
          style={{ padding: "5px 0", width: "40px", height: "25px" }}
        />
        <p>Copy</p>
      </div>
      {/* </div> */}
    </>
  );
};

export default Index;
