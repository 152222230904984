import { BaseUrl } from "./../apis/GlobalConfing";
const qr = require("querystring");

export const UseRegisterUser = async (user_info) => {
  const response = await fetch(`${BaseUrl}/user/register`, {
    method: "POST",
    body: qr.stringify(user_info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};
export const UseVerifyEmailUser = async (user_info) => {
  const response = await fetch(`${BaseUrl}/user/activeemail`, {
    method: "POST",
    body: qr.stringify(user_info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};
