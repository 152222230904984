import { BaseUrl } from "./../apis/GlobalConfing";
const qr = require("querystring");

export const GetNotifications = async () => {
  const info = { token: localStorage.getItem("token") };
  const response = await fetch(`${BaseUrl}/home/notification`, {
    method: "POST",
    body: qr.stringify(info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};
export const SeenNotifications = async (notif_info) => {
  const response = await fetch(`${BaseUrl}/home/addseenNotification`, {
    method: "POST",
    body: qr.stringify(notif_info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};
