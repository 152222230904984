import { UseContactUs } from "../../hooks/contact-us";
import { ErrorMessage, SuccessMessage } from "../../utils/messages";

export const ContactUsAction = (user_info, handleSendSuccess) => {
  return async () => {
    try {
      if (user_info.subject == "") {
        ErrorMessage("Please Enter Subject");
      } else if (user_info.message == "") {
        ErrorMessage("Please Enter Message");
      } else {
        const ticket_info = {
          subject: user_info.subject,
          message: user_info.message,
          token: localStorage.getItem("token"),
        };
        const response = await UseContactUs(ticket_info);
        if (response.sucsses == true) {
          SuccessMessage("Your message successfuly sent");
          handleSendSuccess();
        } else {
          ErrorMessage("Have problem to send message");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
