import { BaseUrl } from "./../apis/GlobalConfing";
export const getDataOverview = async () => {
    const response = await fetch(`${BaseUrl}/home/overview`, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
    const {data} = await response.json();
    return data;
}