import React, { useEffect, useState } from "react";
import "./sidebar.css";
import links from "./links";
import { Link, useLocation } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { chatClose } from "../../../redux/chats/chatAction";
import { useDispatch, useSelector } from "react-redux";
import { GetUserInfoAction } from "./../../../redux/auth/auth.action";
import { GetUserWalletInventoryAction } from "../../../redux/wallet/wallet.action";
import { GetTransactionListAction } from "../../../redux/transaction/transaction.action";
import { CreateUserWalletAddressAction } from "./../../../redux/wallet/wallet.action";
import {
  GetPlanListAction,
  GetPlanListHomeAction,
} from "../../../redux/plan/plan.action";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.Wallet);

  useEffect(() => {
    dispatch(GetUserInfoAction());
    dispatch(GetTransactionListAction());
    dispatch(GetPlanListAction());
    dispatch(GetPlanListHomeAction());
    dispatch(CreateUserWalletAddressAction());
    if (wallet.wallet_address) {
      dispatch(GetUserWalletInventoryAction(wallet.wallet_address));
    }
  }, []);
  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(!isOpen)}>
        <aside className="sidebar">
          <nav className="navbar">
            <ul>
              {links.map((item, i) => (
                <li key={i}>
                  <Link
                    onClick={() => dispatch(chatClose())}
                    key={item.id}
                    to={item.to}
                    className={`${
                      location.pathname === item.to ? "activelink" : ""
                    }`}
                  >
                    <span>{item.icon}</span>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      </ClickAwayListener>
    </>
  );
};

export default Sidebar;
