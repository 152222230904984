const InitState = {
  inventory: {
    balance_bnb: "",
    balance_cyr: "",
  },
  wallet_address: "",
};

export const WalletReducer = (state = InitState, action) => {
  switch (action.type) {
    case "GET_USER_INVENTORY":
      return { ...state, inventory: action.payload };
    case "GET_USER_WALLET_ADDRESS":
      return { ...state, wallet_address: action.payload };
    default:
      return state;
  }
};
