const InitState = {
  private_notif: null,
  public_notif: null,
  notif_text: null,
};

export const NotificationReduser = (state = InitState, action) => {
  switch (action.type) {
    case "GET_PV_NOTIF":
      return { ...state, private_notif: action.payload };
    case "GET_PB_NOTIF":
      return { ...state, public_notif: action.payload };
    case "NOTIF_TEXT":
      return { ...state, notif_text: action.payload };
    default:
      return state;
  }
};
