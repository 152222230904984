import styled from "styled-components";
import Wallet from "./index";
export const Container = styled.div`
  background-color: #d1d6ef;
  padding: 0 20px;
  width: 100%;
`;
export const PackageDetail = styled.div`
  background-color: var(--button-color);
  padding: 74px 76px 126px 45px;
  box-shadow: 7px 5px 6px 0 rgba(0, 0, 0, 0.16);
  width: 80%;
  margin: 40px 0px 126px 0px;
  border-radius: 40px;
  display: flex;
  > div {
    &:first-child {
      width: 33%;
      border-right: 2px solid #707070;
      padding-right: 30px;
    }
    &:nth-child(2) {
      width: 67%;
    }
  }
`;
export const Leftpart = styled.div`
  display: flex;
  > div {
    align-self: center;
  }
`;
export const TopPackage = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NamePack = styled.div`
  font-size: 30px;
  color: #707070;
  font-weight: bold;
`;
export const PriceContainer = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 30px;
  color: #707070;
  span {
    font-size: 18px;
    position: relative;
    top: 8px;
  }
`;
export const Divider = styled.div`
  height: 2px;
  background-color: #707070;
  width: 80%;
  margin: 20px 0;
`;
export const Profits = styled.div`
  font-size: 18px;
  color: #707070;
  font-weight: bold;
  margin: 5px 0;
  span {
    color: #1db800;
    margin-left: 5px;
  }
`;
export const RightPart = styled.div``;
export const ChoosePackage = styled.p`
  font-size: 25px;
  font-weight: bold;
  color: rgba(85, 99, 167, 0.8);
  text-align: center;
`;
export const ChoosePackageSubtext = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: gray;
  text-align: center;
`;

export const CoinContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const AmountField = styled.input`
  height: 40px;
  border-radius: 40px;
  padding-left: 15px;
  width: 60%;
  background-color: rgba(85, 99, 167, 10);
  color: #fff;
  margin-top: 30px;
  ::placeholder {
    color: #ccc;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Coins = styled.div`
  border: ${(props) => (props.active ? "6px solid #3c4572" : "unset")};
  border-radius: 15px;
  margin: 10px;
  transition: all 0.3s ease;
`;
export const Submit = styled.button`
  display: flex;
  background-color: rgba(85, 99, 167, 0.8);
  border-radius: 35px;
  display: flex;
  width: 250px;
  align-self: flex-end;
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: none;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  span {
    font-size: 30px;
    color: #e9e9e9;
  }
`;
export const BackBtn = styled.button`
  display: flex;
  background-color: rgba(85, 99, 167, 0.8);
  border-radius: 35px;
  display: flex;
  width: 150px;
  align-self: flex-end;
  position: absolute;
  left: 50px;
  bottom: 20px;
  border: none;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  span {
    font-size: 20px;
    color: #e9e9e9;
  }
`;
export const BackBtnMobile = styled.button`
  background-color: rgba(85, 99, 167, 0.8);
  border-radius: 35px;
  display: flex;
  width: 250px;
  bottom: 10px;
  border: none;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  margin-top: 10px;
  span {
    font-size: 20px;
    color: #e9e9e9;
  }
`;
export const FinalContainer = styled.div`
  background-color: #e9e9e9;
  padding: 100px 20px;
  width: 100%;
  border-radius: 20px;
  margin: 60px 0;
`;
export const ActivePlanBox = styled.div`
  background-color: #81e392;
  color: black;
  border-radius: 30px;
  padding: 10px 25px;
  border: 1px solid #707070;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;
export const NeedHelpContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Links = styled.a`
  text-decoration: none;
  color: #3c4572;
  font-size: 16px;
  position: relative;
  left: 10px;
`;
export const ContinueButton = styled.button`
  background-color: #3c4572;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #e9e9e9;
  font-size: 16px;
  padding: 10px 40px;
  position: relative;
  font-weight: bold;
  right: 30px;
  border: none;
`;

export const MobileContainer = styled.div`
  text-align: center;
`;
export const MobilePackageContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;
export const MobileProfits = styled.div`
  font-size: 18px;
  color: #707070;
  font-weight: bold;
  margin: 5px 0;
  span {
    color: #1db800;
    margin-left: 5px;
  }
`;
export const MobileNameContainer = styled.div`
  font-size: 18px;
  color: #707070;
  font-weight: bold;
  margin: 5px 0;
  span {
    color: #1db800;
    margin-left: 5px;
  }
`;
export const MobileBtnContainer = styled.div`
  display: block;
  justify-content: center;
`;
export const SubmitMobile = styled.button`
  background-color: rgba(85, 99, 167, 0.8);
  border-radius: 35px;
  display: flex;
  width: 250px;
  bottom: 10px;
  border: none;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  margin-top: 50px;
  span {
    font-size: 30px;
    color: #e9e9e9;
  }
`;
