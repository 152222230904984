import React, { useEffect } from "react";
import "./genealogy.css";
import Layout from "../../common/layout";
import { useDispatch, useSelector } from "react-redux";
import { GetGenealogyListAction } from "../../../redux/genealogy/genealogy.action";
import { GetUserInfoAction } from "../../../redux/auth/auth.action";

const Genealogy = () => {
  let i = 1;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetGenealogyListAction());
    dispatch(GetUserInfoAction());
  }, []);

  const Genealogy = useSelector((state) => state.GenealogyUsers);
  const user_info = useSelector((state) => state.AuthenticationUser);

  return (
    <Layout>
      <div className="mainContainer">
        <span id="back-to-top-anchor" />
        <p className="title">Genealogy</p>
        <div className="mainContent">
          <div>
            <div className="tbl-scroll">
              <div className="title-header">
                <span>#</span>
                <span>Name</span>
                <span>Email</span>
                <span>Date</span>
              </div>
              {Genealogy.GenealogyUsers != null ? (
                Genealogy.GenealogyUsers.length != 0 ? (
                  Genealogy.GenealogyUsers.map((user, index) => (
                    <div className="body-content" key={index}>
                      <>
                        <span>{i++}</span>
                        <span>{user.name}</span>
                        <span>{user.email}</span>
                        <span>{user.createdAt.toString().slice(0, 10)}</span>
                      </>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center" }} className="title-header">
                    <p className="not-gen"> Don't have any Genealogy</p>
                  </div>
                )
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
        <div className="textContainer">
          <div className="caption">
            <p>
              Your Link :
              <span>https://stake.cyruscoin.io?ref={user_info.reffral}</span>
            </p>
          </div>
          <div className="circleTitle">
            <p>
              {Genealogy.GenealogyUsers != null
                ? Genealogy.GenealogyUsers.length
                : ""}
            </p>
            <p>Invited Friends</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Genealogy;
