const qr = require("querystring");
import { BaseUrl } from "./../apis/GlobalConfing";

export const UseContactUs = async (form_info) => {
  const response = await fetch(`${BaseUrl}/home/newticket`, {
    method: "POST",
    body: qr.stringify(form_info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};
