import React, { useState } from "react";
import CopyRight from "../../../common/copy-right/CopyRight";
import Logo from "./../img/logo.png";
import {
  VerifyEmailWrapper,
  VerifyEmailContainer,
  VerifyContentColumn,
  VerifyLogo,
  VerifyH1,
  VerifyEmailInformation,
  VrifyContactUs,
  ContactUsLink,
  TextCenter,
} from "./verifyEmailElements";
import "./style.css";
import { useDispatch } from "react-redux";
import { ForgetPasswordVerifyAction } from "../../../../redux/auth/auth.action";
import { ErrorMessage } from "../../../../utils/messages";

const ForgetPassword = ({ history }) => {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const VerifyAccount = () => {
    const info = {
      code,
      password,
      token: localStorage.getItem("token"),
    };
    if (password.length >= 8 && confirm_password.length >= 8) {
      if (password == confirm_password) {
        dispatch(ForgetPasswordVerifyAction(info));
      } else {
        ErrorMessage("Passwords Not Math");
      }
    } else {
      ErrorMessage("Password must be 8 characters long");
    }
  };
  return (
    <>
      <VerifyEmailWrapper>
        <VerifyEmailContainer>
          <VerifyContentColumn>
            <VerifyLogo src={Logo} />
            <TextCenter>
              <VerifyH1>Set New Password</VerifyH1>
            </TextCenter>
            <input
              className="verify-email-input"
              placeholder="Enter Code..."
              onChange={(e) => setCode(e.target.value)}
            />
            <input
              className="verify-email-input"
              placeholder="New Password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <input
              className="verify-email-input"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
            />
            <TextCenter>
              <button className="verify-email-btn" onClick={VerifyAccount}>
                set new password
              </button>
            </TextCenter>

            <VrifyContactUs>
              Need help? <ContactUsLink> Contact Us </ContactUsLink>
            </VrifyContactUs>
          </VerifyContentColumn>
        </VerifyEmailContainer>
        <CopyRight />
      </VerifyEmailWrapper>
    </>
  );
};

export default ForgetPassword;
