const InitState = {
  GenealogyUsers: null,
};

export const GenealogyReducer = (state = InitState, action) => {
  switch (action.type) {
    case "GET_GENEALOGY_USERS_LIST":
      return { ...state, GenealogyUsers: action.payload };
    default:
      return state;
  }
};
