import {
  ActivePlan,
  AddPriceToPlan,
  GetActivePlanList,
  GetPlanHomeList,
  GetPlanList,
} from "../../hooks/plan";
import { ConnectionError, ErrorMessage } from "../../utils/messages";
import { GetUserInfoAction } from "../auth/auth.action";
import { SuccessMessage } from "./../../utils/messages";

export const GetPlanListAction = () => {
  return async (dispatch) => {
    const response = await GetPlanList();
    await dispatch({
      type: "GET_PLAN_LIST",
      payload: response.plan[0],
    });
  };
};
export const GetPlanListHomeAction = () => {
  return async (dispatch) => {
    const response = await GetPlanHomeList();
    await dispatch({
      type: "GET_PLAN_LIST_HOME",
      payload: response.data,
    });
  };
};
export const ActivePlanAction = (user_info, successfulActive, setLoader) => {
  return async (dispatch) => {
    setLoader(true);
    const response = await ActivePlan(user_info);
    if (response.success == true) {
      successfulActive();
      setLoader(false);
      SuccessMessage(response.data.msg);
      await dispatch(GetUserInfoAction());
    } else if (response.succsess == false) {
      ErrorMessage(response.data.msg);
      setLoader(false);
    } else {
      ConnectionError();
      setLoader(false);
    }
  };
};
export const GetActivePlanLiatAction = () => {
  return async (dispatch) => {
    const response = await GetActivePlanList();
    await dispatch({ type: "GET_ACTIVE_PLAN", payload: response });
  };
};
export const AddPriceToPlanAction = (amount, setAmount) => {
  return async (dispatch) => {
    const response = await AddPriceToPlan(amount);
    if (response.succsess == false) {
      ErrorMessage(response.data.msg);
    } else {
      SuccessMessage(response.data.msg);
      setAmount("");
    }
    await dispatch({type: "LOADING", payload: false});
  };
};
