import React from 'react'
import './button.css'
const MainButton = ({btnLoading , clicked}) => {
  return (
    <button className="mainButton" onClick={clicked}>
      Send
      {btnLoading ? (<div className="spinner-border"></div>) : null}
    </button>
  )
}

export default MainButton;
