import {
  GetNotifications,
  SeenNotifications,
} from "../../hooks/nootifications";

export const GetNotificationListAction = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await GetNotifications();
      await dispatch({
        type: "GET_PB_NOTIF",
        payload: response.Public.reverse(),
      });
      await dispatch({
        type: "GET_PV_NOTIF",
        payload: response.private2.reverse(),
      });
    }
  };
};

export const SeenNotificationAction = (info) => {
  return async (dispatch) => {
    await SeenNotifications(info);
    dispatch(GetNotificationListAction());
  };
};
