import React, { useEffect } from "react";
import notifies from "./notification.data";
import "./notification.css";
import "./../envelope/envelope.css";
import { useDispatch, useSelector } from "react-redux";
import JWT from "jsonwebtoken";
import { Badge } from "@material-ui/core";
import { SeenNotificationAction } from "../../../../../redux/notifications/notification.action";
import { GetNotificationListAction } from "./../../../../../redux/notifications/notification.action";
import { GetSeenLengthAction } from "../../../../../redux/auth/auth.action";

const Notifies = (props) => {
  const notification = useSelector((state) => state.Notification);

  const dispatch = useDispatch();

  const handleClick = (notif_info) => {
    props.click();
    dispatch({ type: "NOTIF_TEXT", payload: notif_info });
    const info = {
      notid: notif_info.id,
      token: localStorage.getItem("token"),
    };
    dispatch(SeenNotificationAction(info));
    dispatch(GetNotificationListAction());
    dispatch(GetSeenLengthAction());
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setInterval(() => {
        dispatch(GetNotificationListAction());
      }, 30000);
    }
  }, []);

  return (
    <>
      {notification.public_notif != null ? (
        notification.public_notif.map((notify) => (
          <div
            key={notify._id}
            className="notify-item"
            onClick={() => handleClick(notify)}
          >
            <p>{notify.title}</p>
            {notify.seen == false ? (
              <Badge
                sm
                badgeContent=""
                overlap="circular"
                max="99"
                color="secondary"
                style={{ position: "absolute", right: "20px" }}
              >
                <span></span>
              </Badge>
            ) : null}
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
      {notification.private_notif != null
        ? notification.private_notif.map((notify) => (
            <div
              key={notify._id}
              className="notify-item"
              onClick={() => handleClick(notify)}
            >
              <p>{notify.title}</p>
              {notify.seen == false ? (
                <Badge
                  sm
                  badgeContent=""
                  overlap="circular"
                  max="99"
                  color="secondary"
                  style={{ position: "absolute", right: "20px" }}
                >
                  <span></span>
                </Badge>
              ) : null}
            </div>
          ))
        : null}
    </>
  );
};

export default Notifies;
