import { ErrorMessage, SuccessMessage } from "./../../utils/messages";
import {
  ChangeCoinRequestTransaction,
  GetTransactionList,
  SearchTransaction,
  SendRequestTransaction,
} from "../../hooks/transaction";
import { GetUserWalletInventoryAction } from "../wallet/wallet.action";

export const GetTransactionListAction = () => {
  return async (dispatch) => {
    const response = await GetTransactionList();
    await dispatch({
      type: "GET_TRANSACTION_LIST",
      payload: response.result.reverse(),
    });
  };
};

export const SendRequestTransactionAction = (
  request_info,
  ChangeBtnText,
  wallet_address
) => {
  return async (dispatch) => {
    const response = await SendRequestTransaction(request_info);
    switch (response.success) {
      case true:
        SuccessMessage(response.data.msg);
        await dispatch({
          type: "SHOW_TRANSACTION_RECIPT",
          payload: response.transaction,
        });
        dispatch(GetTransactionListAction());
        dispatch(GetUserWalletInventoryAction(wallet_address));
        break;
      case false:
        ErrorMessage(response.data.msg);
        ChangeBtnText(false);
        break;
      default:
        return null;
    }
  };
};
export const SearchTransactionAction = (hash, show) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const response = await SearchTransaction({ hash, token });
    if (response.sucsses == true) {
      await dispatch({
        type: "SEARCH_TRANSACTION",
        payload: response.data.hash,
      });
      show();
    } else {
      ErrorMessage(response.data.msg);
    }
  };
};

export const ChangeCoinRequestTransactionAction = (request_info, goNext) => {
  return async (dispatch) => {
    const response = await ChangeCoinRequestTransaction(request_info);
    switch (response.success) {
      case true:
        await dispatch({
          type: "CHANGE_TRANSACTION_RECIPT",
          payload: response.transation,
        });
        goNext();
        SuccessMessage(response.data.message);
        break;
      case false:
        ErrorMessage(response.data.message);
        break;

      default:
        ErrorMessage(response.data.message);
    }
    await dispatch({ type: "LOADING", payload: false });
  };
};
