const data = [
  {
    title: "multi crypto",
    disabled: false,
  },

  {
    title: "payfa",
    disabled: true,
  },
  {
    title: "visa card",
    disabled: true,
  },

  {
    title: "ducat card",
    disabled: true,
  },
];

export default data;
