import styled from "styled-components";

export const AuthFluid = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oswald" !important;
  padding-bottom: 35px;
  padding-top: 35px;
  @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&display=swap");

  @media (max-width: 768px) {
    overflow-y: hidden;
  }
`;
export const AuthContainer = styled.div`
  width: 70vw;
  /*${({ showForm }) =>
    showForm == "register"
      ? "113vmin"
      : `${showForm == "login" ? "80vmi" : "145vmin"}`} ; */
  transition: all 1s ease-in;
  display: flex;
  /* justify-content: ${({ showForm }) =>
    showForm == "login"
      ? "flex-start"
      : `${showForm == "register" ? "flex-end" : "center"}`}; */
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "Oswald" !important;
  @media (max-width: 768px) {
    transition: all 2s;
    flex-direction: ${({ showForm }) =>
      showForm == "login"
        ? "column"
        : `${showForm == "register" ? "column-reverse" : "column"}`};
    justify-content: ${({ showForm }) =>
      showForm == "login"
        ? "flex-end"
        : `${showForm == "register" ? "flex-end" : "center"}`};
    overflow-y: hidden;
    height: 90vh;
    width: 90vw;
  }
`;

export const RegisterSide = styled.div`
  width: ${({ showForm }) => (showForm == "register" ? "100%" : "50%")};
  height: 600px;
  background: rgb(235, 215, 120);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in;
  z-index: ${({ showForm }) => (showForm == "login" ? "1" : "0")};
  box-shadow: ${({ showForm, showRegister }) =>
    showForm == "login"
      ? "3px 0px 18px rgb(235, 215, 120)"
      : `${showRegister == true ? "3px 0px 18px rgb(235, 215, 120)" : ""}`};
  border-radius: 20px 0 0 20px;

  @media (max-width: 768px) {
    border-radius: 10px;
    width: 100%;
    height: ${({ showForm }) =>
      showForm == "register"
        ? "100%"
        : `${showForm == "login" ? "10%" : "600px"}`};
  }
`;

export const RegisterContent = styled.div`
  font-family: "Oswald" !important;
  display: ${({ showForm }) => (showForm == "login" ? "none" : "flex")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RegisterTitle = styled.h1`
  font-size: 45px;
  font-weight: 600;
  margin-right: ${({ showForm }) => (showForm == "register" ? "" : "14vmin")};
  color: rgb(38, 38, 38);
  opacity: ${({ showForm }) => (showForm == "login" ? "0" : "100")};
  cursor: pointer;
  /* transform: ${({ showForm }) =>
    showForm == "register" ? "translate(0 ,-3rem)" : ""}; */
  transition: all 1s ease-in;
  font-family: "Oswald" !important;
  transform: ${({ showForm }) =>
    showForm == "register" ? "translateY(-4rem)" : "TranslateY(9rem)"};

  @media (max-width: 768px) {
    margin-right: 0;
  }
  @media (max-width: 325px) {
    transform: ${({ showForm }) =>
      showForm == "register" ? "translateY(-1rem)" : "TranslateY(9rem)"};
  }

  @media (max-width: 360px) {
    transform: ${({ showForm }) =>
      showForm == "register" ? "translateY(-1rem)" : "TranslateY(9rem)"};
  }
`;

export const RegisterForm = styled.div`
  height: auto;
  width: ${({ showForm }) => (showForm == "register" ? "100%" : "0")};
  display: flex;
  transition: all 2s ease-in-out;
  opacity: ${({ showForm }) => (showForm == "register" ? "100" : "0")};
  z-index: ${({ showForm }) => (showForm == "register" ? "1" : "-1")};
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid #dbdbdb;
  margin-bottom: 45px;
`;

export const FieldWrapperRegister = styled.div`
  position: relative;
`;
export const FieldError = styled.div`
  position: absolute;
  right: -125px;
  top: 5px;
  background: red;
  height: 47px;
  border-radius: 0 45px 45px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 43px;
  padding-right: 26px;
  color: #fff;
  z-index: -1 !important;
  font-family: "Oswald" !important;
`;

export const FieldSuccess = styled.div`
  position: absolute;
  right: -60px;
  top: 5px;
  background: green;
  height: 47px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 46px;
  padding-right: 26px;
  color: #fff;
  z-index: -1 !important;
`;

export const RegisterInput = styled.input`
  height: 45px;
  border-radius: 45px;
  background: #fff;
  width: 300px;
  padding-left: 10px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 100001 !important;
  border: none;
  outline: none;

  ::placeholder {
    color: #ccc;
    font-family: "Microsoft YaHei" !important;
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`;

export const RegisterBtn = styled.button`
  padding: 17px 40px;
  margin-top: 20px;
  width: 200px;
  border-radius: 10px;
  border: none;
  background: rgb(38, 38, 38);
  color: rgb(235, 215, 120);
  font-weight: bold;
  font-family: "Microsoft YaHei" !important;
  bottom: -17px;
  left: -18px;
  position: absolute;
  display: ${({ showForm }) => (showForm == "register" ? "block" : "none")};

  @media (max-width: 768px) {
    position: unset;
    padding: 10px 40px;
  }
`;

// export const HomeSide = styled.div`
//   position: absolute;
//   cursor: pointer;
//   z-index: ${({ showForm }) => (showForm == "login" ? "1" : "0")};
// `;

export const HomeSideImg = styled.img`
  transition: all 1s ease-in;
  border: 10px solid rgb(235, 215, 120);
  border-radius: 500px;
  width: ${({ showForm }) =>
    showForm == "register"
      ? "220px"
      : `${showForm == "login" ? "220px" : "250px"}`};
  position: absolute;
  cursor: pointer;
  z-index: 1;
  transform: ${({ showForm }) =>
    showForm == "register"
      ? "  translateX(23vw)"
      : `${showForm == "login" ? "translateX(-23vw)" : "translateX(0)"}`};
  @media (max-width: 768px) {
    width: ${({ showForm }) =>
      showForm == "login"
        ? "66px"
        : `${showForm == "register" ? "66px" : "180px"}`};
    border: ${({ showForm }) =>
      showForm == "register" ? " 5px solid #ebd788" : "none"};
    transform: ${({ showForm }) =>
      showForm == "login"
        ? "translateY(-40vh)"
        : `${showForm == "register" ? "translateY(-40vh)" : ""}`};
    transform: translateX(0);
    top: ${({ showForm }) => (showForm == "login" ? "0" : "")};
  }
  /* ${
    "" /* position: relative;
  right: ${({ showForm }) => (showForm == "login" ? "450px" : "0")}; */
  } */
  @media (max-width: 325px) {
    width: ${({ showForm }) =>
      showForm == "login"
        ? "50px"
        : `${showForm == "register" ? "50px" : "150px"}`};
  }
`;

export const LoginSide = styled.div`
  width: ${({ showForm }) =>
    showForm == "login" ? "100%" : `${showForm == "register" ? "35%" : "50%"}`};
  height: 600px;
  background: rgb(38, 38, 38);
  border-radius: 0px 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in;

  @media (max-width: 768px) {
    border-readius: 10px;
    width: 100%;
    border-radius: 0 0 0 0;
    height: ${({ showForm }) =>
      showForm == "login"
        ? "100%"
        : `${showForm == "register" ? "10%" : "600px"}`};
  }
`;

export const LoginContent = styled.div`
  visibility: ${({ showForm }) =>
    showForm == "register" ? "hidden" : "visible"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoginTitle = styled.h1`
  font-size: 45px;
  color: rgb(235, 215, 120);
  font-weight: 600;
  cursor: pointer;
  opacity: ${({ showForm }) => (showForm == "register" ? "0" : "100")};
  transform: ${({ showForm }) =>
    showForm == "login" ? "translate(0 ,9rem)" : ""};
  transition: all 1s ease-in;
  margin-left: ${({ showForm }) => (showForm == "login" ? "" : "14vmin")};

  transform: ${({ showForm }) =>
    showForm == "login" ? "translateY(-5vmax)" : "TranslateY(8rem)"};

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const LoginForm = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  transition: all 1.2s ease-in;
  opacity: ${({ showForm }) => (showForm == "login" ? "100" : "0")};
  z-index: ${({ showForm }) => (showForm == "login" ? "1" : "-1")};
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoginInput = styled.input`
  border: none;
  height: 45px;
  background: transparent;
  width: 400px;
  padding-left: 10px;
  font-weight: bold;
  color: #fff;
  background: transparent;
  font-size: 18px;
  color: #c3c3c3;
  line-height: 1.2;
  padding: 0 2px;
  font-family: "Microsoft YaHei" !important;

  ::placeholder {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 280px;
  }
`;

//Inputs

export const InputiLabel = styled.div`
  font-size: 18px;
  color: #ebd778;
  line-height: 1.2;
  padding-left: 2px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const BorderBottom = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  ::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    background: #d5007d;
    background: -webkit-linear-gradient(45deg, #ffdea8 #fec84e);
    background: -o-linear-gradient(45deg, #ffdea8, #fec84e);
    background: -moz-linear-gradient(45deg, #ffdea8, #fec84e);
    background: linear-gradient(45deg, #ffdea8, #fec84e);
  }
`;

export const BtnLoginWrapper = styled.div`
  width: 290px;
  justify-content: space-around;
  position: absolute;
  align-items: flex-end;
  bottom: 0;
  right: -40px;
  display: ${({ showForm }) => (showForm == "login" ? "flex" : "none")};

  @media (max-width: 768px) {
    /* flex-direction: column;
    align-items: center;
    width: 100%;
    right: auto;
    left: auto;
    height: 55%; */

    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    right: auto;
    left: auto;
    height: 34%;
    justify-content: center;
  }
  @media (max-width: 325px) {
    height: 27%;
  }
`;

export const ButtonLogin = styled.button`
  outline: none;
  border: none;
  position: relative;
  background: rgb(235 215 120);
  color: #333;
  border-radius: 10px;
  padding: 12px 40px;
  top: 10px;

  @media (max-width: 768px) {
    padding: 10px 40px;
  }
`;

export const ForgetLink = styled.button`
  color: #ebd788;
  text-decoration: underline;
  position: relative;
  bottom: 15px;
  background: transparent;
  border: none;
  @media (max-width: 768px) {
    bottom: 0;
  }
  @media (max-width: 325px) {
    padding: 9px 38px;
  }
`;
