import React, { useState } from "react";
import "./envelope.css";
import Badge from "@material-ui/core/Badge";
import { useDispatch, useSelector } from "react-redux";
import { chatAction } from "../../../../../redux/chats/chatAction";
import {
  GetTicketMessageAction,
  SeenTicketAction,
} from "../../../../../redux/tickets/ticket.action";
import { GetSeenLengthAction } from "../../../../../redux/auth/auth.action";

const Index = (props) => {
  const dispatch = useDispatch();
  const Tickets = useSelector((state) => state.Tickets);

  const hanldeShowMessage = (chat) => {
    dispatch(chatAction());
    dispatch(GetTicketMessageAction(chat.id));
    dispatch({ type: "GET_TICKET_INFO", payload: chat });
    const info = {
      ticketid: chat.id,
      token: localStorage.getItem("token"),
    };
    dispatch(SeenTicketAction(info));
    dispatch(GetSeenLengthAction());
  };
  return (
    <>
      {Tickets.ticket_list !== null ? (
        Tickets.ticket_list.length != 0 ? (
          Tickets.ticket_list.map((chat) => (
            <div
              onClick={() => hanldeShowMessage(chat)}
              key={chat.id}
              className="chat-item"
            >
              <p>{chat.subject}</p>
              {chat.seenuser == true ? null : (
                <Badge
                  sm
                  badgeContent=""
                  overlap="circular"
                  max="99"
                  color="secondary"
                >
                  <span></span>
                </Badge>
              )}
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <p style={{ textAlign: "center" }}>Don't have any messages</p>
          </div>
        )
      ) : (
        <div>loading</div>
      )}
    </>
  );
};

export default Index;
