import React, { useState } from "react";
import {
  ShowChatContainer,
  ShowChatContent,
  RecivedMessage,
  Sendmessage,
  Messages,
  InputField,
  Input,
  AlignLeft,
  AlignRight,
  RecivedMessageLabel,
  SendmessageLabel,
  NameText,
} from "./ShowChatElements";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import AddImage from "@material-ui/icons/ImageOutlined";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  GetTicketMessageAction,
  SendMessageAction,
} from "../../../../redux/tickets/ticket.action";
import { ErrorMessage, SuccessMessage } from "../../../../utils/messages";
import { useEffect } from "react";

const customStyles = makeStyles(() => ({
  TextField: {
    borderBottom: "none",
    width: "100%",
    position: "relative",
    outline: "none",
    border: "none",
    backgroundColor: "#b3bdef",
    borderRadius: "25px",
    color: "#3c4572",
    padding: "20px",
    marginTop: "50px",
  },
  // root: {
  //   bottom: 0,
  //   position: "fixed",
  //   zIndex: 999,
  //   width: "100vw !important",
  // },
  button: {
    backgroudColor: "red",
  },
  input: {
    paddingRight: 14,
    paddingTop: 3,
  },
  fileInput: {
    display: "none",
  },
}));

const Classes = makeStyles(() => ({
  underline: {
    underline: {
      "&&&:before": {
        borderBottom: "none !important",
      },
      "&&:after": {
        borderBottom: "none !important",
      },
      "&&:focus": {
        borderBottom: "none !important",
      },
    },
  },
}));

const ShowChats = () => {
  const styles = customStyles();
  const classes = Classes();
  const user_info = useSelector((state) => state.AuthenticationUser);
  const Tickets = useSelector((state) => state.Tickets);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const success = () => {
    setMessage("");
    dispatch(GetTicketMessageAction(Tickets.selected_ticket.id));
    SuccessMessage("Successfuly Sent");
  };

  const sendMessage = () => {
    if (message != "") {
      dispatch(SendMessageAction(Tickets.selected_ticket.id, message, success));
    } else {
      ErrorMessage("Please Type Message");
    }
  };

  useEffect(() => {
    if (Tickets.selected_ticket != null) {
      localStorage.setItem("id", Tickets.selected_ticket.id);
      var getMessages = setInterval(() => {
        dispatch(GetTicketMessageAction(localStorage.getItem("id")));
      }, 30000);
    }
    return () => {
      clearInterval(getMessages);
    };
  }, []);

  return (
    <>
      <NameText>{user_info.name}</NameText>
      <ShowChatContainer>
        <ShowChatContent>
          {Tickets.ticket_messages.length != 0
            ? Tickets.ticket_messages.map((chat) =>
                chat.from == "user" ? (
                  <>
                    <div align="right">
                      <SendmessageLabel>Me</SendmessageLabel>
                      <Sendmessage align="left">{chat.message}</Sendmessage>
                      <SendmessageLabel>
                        {chat.date.toString().slice(0, 10)} -{" "}
                        {chat.date.toString().slice(11, 16)}
                      </SendmessageLabel>
                    </div>
                  </>
                ) : (
                  <div align="left">
                    <RecivedMessageLabel>support</RecivedMessageLabel>
                    <RecivedMessage align="right">
                      {chat.message}
                    </RecivedMessage>
                    <RecivedMessageLabel>
                      {chat.date.toString().slice(0, 10)} -{" "}
                      {chat.date.toString().slice(11, 16)}
                    </RecivedMessageLabel>
                  </div>
                )
              )
            : null}
        </ShowChatContent>
        <TextField
          inputProps={{
            classes,
          }}
          className={styles.TextField}
          required
          placeholder="message..."
          fullWidth
          variant="filled"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={
            Tickets.active_ticket != null
              ? Tickets.active_ticket == 1
                ? false
                : true
              : true
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit" onClick={sendMessage}>
                <SendIcon htmlColor="blue" />
              </IconButton>
            </InputAdornment>
          }
        />
      </ShowChatContainer>
    </>
  );
};

export default ShowChats;
