const InitState = {
  plan_list: null,
  plan_list_home: null,
  active_plan: null,
};

export const PlanReducer = (state = InitState, action) => {
  switch (action.type) {
    case "GET_PLAN_LIST":
      return { ...state, plan_list: action.payload };
    case "GET_PLAN_LIST_HOME":
      return { ...state, plan_list_home: action.payload };
    case "GET_ACTIVE_PLAN":
      return { ...state, active_plan: action.payload };
    default:
      return state;
  }
};
