import { BaseUrl } from "./../apis/GlobalConfing";
const qr = require("querystring");

export const SendRequestTransaction = async (req_info) => {
  const response = await fetch(`${BaseUrl}/blockchain/createtransction`, {
    method: "POST",
    body: qr.stringify(req_info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};
export const SearchTransaction = async (dt) => {
  const response = await fetch(`${BaseUrl}/home/searchtransction`, {
    method: "POST",
    body: qr.stringify(dt),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};

export const ChangeCoinRequestTransaction = async (req_info) => {
  const response = await fetch(`${BaseUrl}/blockchain/Buycyrus`, {
    method: "POST",
    body: qr.stringify(req_info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};

export const GetTransactionList = async () => {
  const token = localStorage.getItem("token");
  const info = {
    token,
  };
  const response = await fetch(`${BaseUrl}/blockchain/Gettransaction`, {
    method: "POST",
    body: qr.stringify(info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();
  return data;
};


export const POSTamountToUsd = async (amount) => {
  const token = localStorage.getItem("token");
  const info = {
    token,
    amount
  };
  const response = await fetch(`${BaseUrl}/blockchain/cyrustousd`, {
    method: "POST",
    body: qr.stringify(info),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  const data = await response.json();

  return data;
};