import React, { useState } from "react";
import image1 from "../../../assets/package-1.png";
import GoBack from "../../common/back-wallet-btn";
import MainButton from "../../common/button";
import Layout from "../../common/layout";
import Index from "../home/methodes/index";
import AddPlan from "./addPlan";
import Finance from "./finance";
import CreditCards from "./creditCards";
import HistoryTitle from "./historyTitle";
import packageData from "./package.data";
import packsObject from "./packs.object";
import Pakage from "./pakage";
import Receive from "./receive";
import Send from "./send";
import Recipt from "./recipt";
import Fade from "@material-ui/core/Fade";
import "./wallet.css";
import {
  ChoosePackage,
  Container,
  Divider,
  Leftpart,
  Coins,
  CoinContainer,
  NameContainer,
  NamePack,
  PackageDetail,
  PriceContainer,
  Profits,
  RightPart,
  TopPackage,
  Submit,
  Links,
  FinalContainer,
  ActivePlanBox,
  NeedHelpContainer,
  ContinueButton,
  ChoosePackageSubtext,
  AmountField,
  BackBtn,
  MobileContainer,
  MobilePackageContainer,
  MobileProfits,
  MobileNameContainer,
  SubmitMobile,
  MobileBtnContainer,
  BackBtnMobile,
} from "./wallet.styled";

import WalletManage from "./walletManage";
import AddWallet from "./addWallet";
import Transfer from "./transfer";
import { useDispatch, useSelector } from "react-redux";
import { GetUserWalletInventoryAction } from "../../../redux/wallet/wallet.action";
// import socket from "./../../../socket/index";
import { GetTransactionListAction } from "../../../redux/transaction/transaction.action";
import {
  ActivePlanAction,
  GetPlanListAction,
} from "../../../redux/plan/plan.action";
import { ErrorMessage, SuccessMessage } from "../../../utils/messages";
import { Zoom } from "@material-ui/core";

const Wallet = () => {
  const [showWallet, setShowWallet] = useState(false);
  const [showWalletMannage, setShowWalletMannage] = useState(true);
  const [showSend, setShowSend] = useState(false);
  const [showReceive, setShowReceive] = useState(false);
  const [showAddWallet, setShowAddWallet] = useState(false);
  const [step, setStep] = useState(2);
  const [loader, setLoader] = useState(false);

  const [width, setWidth] = useState(1024);
  const [amount, setAmount] = useState(0);

  const dispatch = useDispatch();

  const addplan = () => {
    setShowWallet(true);
  };
  const goback = () => {
    dispatch({ type: "HIDE_TRANSACTION_RECIPT" });
    setShowWalletMannage(true);
    setShowSend(false);
    setShowReceive(false);
  };
  const backhandler = () => {
    setShowWallet(false);
    setStep(2);
  };
  const sendhandler = () => {
    setShowSend(true);
    setShowWalletMannage(false);
  };
  const receivehandler = () => {
    setShowReceive(true);
    setShowWalletMannage(false);
  };
  const goNextStep = () => {
    setStep(2);
  };
  const [index, setIndex] = useState(1);
  const [selectedPack, setSelectedPack] = useState({});

  const selectCoin = (item) => {
    setIndex(item.id);
    setSelectedPack(item);
  };
  const plans = useSelector((state) => state.Plan);
  const user_info = useSelector((state) => state.AuthenticationUser);
  const wallet = useSelector((state) => state.Wallet);

  const successfulActive = () => {
    setShowWallet(false);
    setAmount(0);
    setStep(3);
  };

  const finalStep = () => {
    const info = {
      amount,
      token: localStorage.getItem("token"),
      idplan: plans.plan_list._id,
    };
    if (amount == 0 || amount == "") {
      ErrorMessage("Please enter the valid amount");
    } else {
      dispatch(ActivePlanAction(info, successfulActive, setLoader));
    }
  };

  React.useEffect(() => {
    setWidth(window.innerWidth);
    const interval = setInterval(() => {
      dispatch(GetUserWalletInventoryAction());
      dispatch(GetTransactionListAction());
      dispatch(GetPlanListAction());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleGoBack = () => {
    alert("sssssssssssss");
  };

  return (
    <Layout>
      {showAddWallet ? (
        <Fade in={showAddWallet}>
          {step === 4 ? (
            <AddWallet
              goBack={() => {
                setShowAddWallet(false);
                setStep(2);
              }}
              goNext={() => setStep(5)}
            />
          ) : (
            <Transfer
              step={step}
              goBack={() => setStep((step) => step - 1)}
              goNext={() => setStep((step) => step + 1)}
            />
          )}
        </Fade>
      ) : (
        <Fade in={!showAddWallet}>
          {showWallet ? (
            <div className="mainContainer ">
              <div className="mainContent wallet">
                <span id="back-to-top-anchor" />
                {step === 1 ? (
                  packageData.map((p) => (
                    <Pakage
                      key={p.id}
                      type={p.type}
                      maincolor={p.maincolor}
                      name={p.name}
                      image={p.image}
                      month={p.month}
                      p1={p.p1}
                      p2={p.p2}
                      p3={p.p3}
                      goNextStep={goNextStep}
                    />
                  ))
                ) : step === 2 ? (
                  <Zoom in={step == 2 ? true : false} timeout={2000}>
                    <Container>
                      {width > 768 ? (
                        <PackageDetail>
                          <Leftpart>
                            <div>
                              <TopPackage>
                                <NameContainer>
                                  <NamePack>
                                    {plans.plan_list != null
                                      ? plans.plan_list.nameplan
                                      : null}
                                  </NamePack>
                                  <PriceContainer>
                                    12
                                    <span>Month</span>
                                  </PriceContainer>
                                </NameContainer>
                                <img
                                  src={image1}
                                  alt="image1"
                                  width="80px"
                                  height="80px"
                                />
                              </TopPackage>
                              <Divider />
                              <Profits>
                                PROFIT
                                <span>
                                  {plans.plan_list != null
                                    ? plans.plan_list.profit
                                    : null}{" "}
                                  %
                                </span>
                              </Profits>
                              <Profits>
                                COMMISION<span>0.5 - 2 Cyrus</span>
                              </Profits>
                            </div>
                            <BackBtn onClick={backhandler}>
                              <span>Back</span>
                            </BackBtn>
                          </Leftpart>
                          <RightPart>
                            <ChoosePackage>Enter Amount</ChoosePackage>
                            <ChoosePackageSubtext>
                              Enter the amount required to staking
                            </ChoosePackageSubtext>
                            <CoinContainer>
                              <AmountField
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder={
                                  wallet.inventory != null
                                    ? wallet.inventory.balance_cyr
                                    : "0"
                                }
                              />
                            </CoinContainer>
                            <Submit onClick={finalStep}>
                              <span>
                                {" "}
                                {loader == true ? "Wating..." : "Submit"}
                              </span>
                            </Submit>
                          </RightPart>
                        </PackageDetail>
                      ) : (
                        <MobileContainer>
                          <MobilePackageContainer>
                            <MobileNameContainer>
                              <NamePack>
                                {plans.plan_list != null
                                  ? plans.plan_list.nameplan
                                  : null}
                              </NamePack>
                              <PriceContainer>
                                12
                                <span>Month</span>
                              </PriceContainer>
                            </MobileNameContainer>
                            <img
                              src={image1}
                              alt="image1"
                              width="80px"
                              height="80px"
                            />
                          </MobilePackageContainer>
                          <MobileProfits>
                            PROFIT
                            <span>
                              {plans.plan_list != null
                                ? plans.plan_list.profit
                                : null}{" "}
                              %
                            </span>
                          </MobileProfits>
                          <MobileProfits>
                            COMMISION<span>0.5 - 2 Cyrus</span>
                          </MobileProfits>
                          <hr />
                          <ChoosePackage>Enter Amount</ChoosePackage>
                          <ChoosePackageSubtext>
                            Enter the amount required to staking
                          </ChoosePackageSubtext>
                          <CoinContainer>
                            <AmountField
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              placeholder="0 cyros of 400"
                            />
                          </CoinContainer>
                          <MobileBtnContainer>
                            <SubmitMobile onClick={finalStep}>
                              <span>
                                {loader == true ? "Wating..." : "Submit"}
                              </span>
                            </SubmitMobile>
                            <BackBtnMobile onClick={backhandler}>
                              <span>Back</span>
                            </BackBtnMobile>
                          </MobileBtnContainer>
                        </MobileContainer>
                      )}
                    </Container>
                  </Zoom>
                ) : null}
                {step === 3 ? (
                  <FinalContainer>
                    <ActivePlanBox>Your plan has been activated</ActivePlanBox>
                    <NeedHelpContainer>
                      <Links href="#">if you need help ? Contact Us</Links>
                      <ContinueButton onClick={backhandler}>
                        continue
                      </ContinueButton>
                    </NeedHelpContainer>
                  </FinalContainer>
                ) : null}
                {step === 1 && (
                  <div className="leftButton">
                    <MainButton title="Back" clicked={backhandler} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mainContainer wallet">
              <div className="center-container">
                {" "}
                <span id="back-to-top-anchor" />
                <p className="wallet-title ">Wallet</p>
                <div
                  className={
                    showWalletMannage
                      ? "wallet-manage"
                      : showSend
                      ? "send-manage"
                      : showReceive
                      ? "recive-manager"
                      : ""
                  }
                >
                  {(showSend || showReceive) && <GoBack clicked={goback} />}
                  {showWalletMannage && (
                    <WalletManage
                      addWallet={setShowAddWallet}
                      goStep={setStep}
                      receiveClick={receivehandler}
                      sendClick={sendhandler}
                    />
                  )}
                  {showSend && <Send />}
                  {showReceive && <Receive />}
                </div>
                <HistoryTitle />
              </div>
              <div className="right-container">
                {user_info.plan == false ? (
                  <AddPlan onClick={addplan} />
                ) : (
                  <Finance />
                )}
                <CreditCards />
              </div>
            </div>
          )}
        </Fade>
      )}
    </Layout>
  );
};

export default Wallet;
