const InitState = {
  show_receipt: false,
  recipt_info: null,
  receipt_change: null,
  transactions: null,
  search: null,
};

export const TransactionReducer = (state = InitState, action) => {
  switch (action.type) {
    case "SHOW_TRANSACTION_RECIPT":
      return { ...state, show_receipt: true, recipt_info: action.payload };
    case "HIDE_TRANSACTION_RECIPT":
      return { ...state, show_receipt: false };
    case "CHANGE_TRANSACTION_RECIPT":
      return { ...state, receipt_change: action.payload };
    case "GET_TRANSACTION_LIST":
      return { ...state, transactions: action.payload };
    case "SEARCH_TRANSACTION":
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

export const loadingReducer = (state = false, action) => {
  switch(action.type) {
    case "LOADING":
      return action.payload;
    default:
      return state;
  }
}