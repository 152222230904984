import {
  ActiveTwoAuth,
  ChangeEmail,
  ChangePassword,
  ChangeUserName,
  ChangeUserProfile,
  ComplateTwoAuth,
  ForgetPasswordFirst,
  ForgetPasswordVerify,
  GetSeensLength,
  TurnOffTwoAuth,
  UseGetUserInfo,
  VerifyChangeEmail,
} from "./../../hooks/auth";
import { ErrorMessage, SuccessMessage } from "./../../utils/messages";

export const GetUserInfoAction = () => {
  return async (dispatch) => {
    const response = await UseGetUserInfo();
    await dispatch({ type: "GET_USER_INFO", payload: response.data });
  };
};
export const ChangeUserNameAction = (name, closeEditProfile) => {
  return async (dispatch) => {
    const response = await ChangeUserName(name);
    if (response.sucssec == true) {
      await dispatch(GetUserInfoAction());
      SuccessMessage("your name successfully changed.");
      closeEditProfile();
    } else {
      ErrorMessage("Have problem to change name");
    }
  };
};
export const ChangeUserProfileAction = (user_profile, id, closeEditProfile) => {
  return async (dispatch) => {
    const data = new FormData();
    data.append('image', user_profile);
    data.append('id', id);
    data.append('token', localStorage.getItem('token'));
    const response = await ChangeUserProfile(data);
    if (response.sucsses == true) {
      await dispatch(GetUserInfoAction());
      SuccessMessage("Profile picture changed!");
      closeEditProfile();
    } else {
      ErrorMessage(response.message);
    }
  };
};

export const ActiveTwoAuthAction = (success) => {
  return async (dispatch) => {
    const response = await ActiveTwoAuth();
    await dispatch({
      type: "GET_TWO_AUTH_INFO",
      payload: response.data,
    });
    success();
    dispatch(GetUserInfoAction());
  };
};

export const TurnOffTwoAuthAction = (success, code) => {
  return async (dispatch) => {
    const response = await TurnOffTwoAuth(code);
    if (response.success == false) {
      ErrorMessage(response.msg);
    } else {
      SuccessMessage("Successful turning off Two-Facktor Authentication");
      dispatch(GetUserInfoAction());
      success();
    }
  };
};

export const ChangePasswordAction = (new_pass) => {
  return async () => {
    const response = await ChangePassword(new_pass);
    if (response.sucssec == true) {
      SuccessMessage("Successfuly changed password");
    } else {
      ErrorMessage("Have problem to change password");
    }
  };
};
export const ChangeEmailAction = (new_email, success) => {
  return async () => {
    const response = await ChangeEmail(new_email);
    if (response.success == true) {
      localStorage.setItem("chet", response.token);
      SuccessMessage(response.data);
      success();
    }
  };
};
export const VerrifyEmailAction = (code, success) => {
  return async (dispatch) => {
    const response = await VerifyChangeEmail(code);
    if (response.success == false) {
      ErrorMessage(response.msg);
    } else if (response.success == true) {
      SuccessMessage(response.msg);
      localStorage.removeItem("chet");
      success();
      dispatch(GetUserInfoAction());
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
};
export const ComplateTwoAuthAction = (code, success) => {
  return async () => {
    const response = await ComplateTwoAuth(code);
    if (response.success == false) {
      ErrorMessage(response.data.msg);
    } else if (response.success == true) {
      SuccessMessage(response.data.msg);
      success();
    }
  };
};
export const ForgetPasswordFirstAction = (email) => {
  return async (dispatch) => {
    const response = await ForgetPasswordFirst(email);
    if (response.sucssec == true) {
      SuccessMessage(response.data.msg);
      localStorage.setItem("token", response.data.token);
      setTimeout(() => {
        window.location.replace("/auth/forget-password");
      }, 2000);
    } else if (response.sucssec == false) {
      ErrorMessage(response.data.msg);
    }
  };
};
export const ForgetPasswordVerifyAction = (email) => {
  return async (dispatch) => {
    const response = await ForgetPasswordVerify(email);
    if (response.sucssec == true) {
      SuccessMessage(response.data.msg);
      setTimeout(() => {
        window.location.replace("/");
      }, 2000);
      localStorage.clear();
    } else {
      ErrorMessage(response.data.msg);
    }
  };
};

export const GetSeenLengthAction = (success) => {
  return async (dispatch) => {
    const response = await GetSeensLength();
    await dispatch({ type: "GET_SEENS", payload: response });
  };
};
