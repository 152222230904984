import React, { useState } from "react";
import "./creditCards.css";
import Card from "./img/Card.PNG";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const CreditCards = () => {
  const [cards, setCards] = useState([
    { id: 0, src: "https://picsum.photos/512/300?random=1", alt: "random 1" },
    { id: 1, src: "https://picsum.photos/512/300?random=2", alt: "random 2" },
    { id: 2, src: "https://picsum.photos/512/300?random=3", alt: "random 3" },
  ]);
  const [activeCard, setActiveCard] = useState(0);

  const goNextImg = () => {
    const nextId = activeCard + 1 >= cards.length ? 0 : activeCard + 1;

    setActiveCard(nextId);
  };

  const goPrevImg = () => {
    const prevId = activeCard - 1 < 0 ? cards.length - 1 : activeCard - 1;

    setActiveCard(prevId);
  };

  return (
    <div className="credit-cards">
      <div className="title">
        <h4>YOUR CARDS</h4>
        <div className="arrows">
          <button onClick={goPrevImg} type="button">
            <ChevronLeftIcon />
          </button>
          <button onClick={goNextImg} type="button">
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <div className="images">
        <img src={Card} />
      </div>
      <div className="dots text-center col-12 p-0 m-0">
        <ul className={"font-size-0 list-unstyled text-center col-12 p-0 m-0"}>
          {cards.map((img, i) => (
            <li key={i}>
              <button
                className={`${activeCard === img.id ? "active" : ""} p-0 m-0`}
                onClick={() => setActiveCard(img.id)}
                type={"button"}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CreditCards;
