import React, { useEffect } from "react";
import "./walletManage.css";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { GetUserWalletInventoryAction } from "../../../../redux/wallet/wallet.action";
import { CreateUserWalletAddressAction } from "./../../../../redux/wallet/wallet.action";

const Index = ({ sendClick, receiveClick, goStep, addWallet }) => {
  const wallet = useSelector((state) => state.Wallet);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(CreateUserWalletAddressAction());
    }, 40000);
    if (wallet.wallet_address) {
      dispatch(GetUserWalletInventoryAction(wallet.wallet_address));
    }
    return () => {
      clearInterval(timer);
    };
  }, [wallet.wallet_address]);

  if (wallet.inventory.balance_cyr != undefined) {
    var cyrusIndex = wallet.inventory.balance_cyr.toString().indexOf(".");
    var cyrusNo = wallet.inventory.balance_cyr
      .toString()
      .slice(0, cyrusIndex + 3);
  }

  return (
    <>
      <div className="content">
        <p className="head">YOUR BALANCE</p>
        <div className="add">
          <h2 className="balance">
            <span>{cyrusNo}</span>
            CYRUS
          </h2>
          <div style={{ display: "flex" }}>
            <h5 style={{ textAlign: "center", marginRight: "10px" }}>
              <span
                style={{
                  border: "2px solid #5162bb",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "50px",
                }}
              >
                {wallet.inventory.balance_ada != undefined
                  ? wallet.inventory.balance_ada
                  : "0"}
              </span>
              <br />
              <br />
              ADA
            </h5>
            <h5 style={{ textAlign: "center", marginRight: "10px" }}>
              <span
                style={{
                  border: "2px solid #5162bb",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "50px",
                }}
              >
                {wallet.inventory.balance_bnb != undefined
                  ? wallet.inventory.balance_bnb.toString().slice(0, 8)
                  : "0"}
              </span>
              <br />
              <br />
              BNB
            </h5>

            <h5 style={{ textAlign: "center", marginRight: "10px" }}>
              <span
                style={{
                  border: "2px solid #5162bb",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "50px",
                }}
              >
                {wallet.inventory.balance_usdt != undefined
                  ? wallet.inventory.balance_usdt
                  : "0"}
              </span>
              <br />
              <br />
              USDT
            </h5>
          </div>
          <button
            onClick={() => {
              addWallet(true);
              goStep(4);
            }}
          >
            <AddIcon style={{ fontSize: "20px" }} />
          </button>
        </div>
        {/* <p className="saving">
          <span>Saving A/C </span>: x x x x x x x x 445
        </p> */}
      </div>
      <div className="actions">
        <button onClick={sendClick} className="send">
          Send
        </button>
        <button onClick={receiveClick} className="resive">
          Recive
        </button>
      </div>
    </>
    // </div>
  );
};

export default Index;
