export const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_INFO":
      return { ...action.payload };
    default:
      return state;
  }
};

export const TwoAuthReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_TWO_AUTH_INFO":
      return { ...action.payload };
    default:
      return state;
  }
};

export const LoginReducer = (state = false, action) => {
  switch (action.type) {
    case "ShowLogin":
      return (state = true);
    case "CloseSection":
      return (state = false);
    default:
      return state;
  }
};

export const SignUpReducer = (state = false, action) => {
  switch (action.type) {
    case "ShowSignUp":
      return (state = true);
    case "CloseSectionSignUp":
      return (state = false);
    default:
      return state;
  }
};

export const SeensReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_SEENS":
      return { ...action.payload };
    default:
      return state;
  }
};
