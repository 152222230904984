import React, { useState } from "react";
import "./send.css";
import tick from './img/tick.png';
import { useSelector } from "react-redux";

const Index = () => {
  const [zIndex1, setZIndex1] = useState(0);
  const [zIndex2, setZIndex2] = useState(0);

  const transaction = useSelector((state) => state.Transaction);

  return (
    // <div className="send-manage">
    <>
      <>
        <div className="content">
          <h3>
            Success Transaction
            <span>
              <img src={tick} alt="tick" />
              SuccessFul
            </span>
          </h3>
          <div className="item-container">
            <div id="address" className="item1" style={{ zIndex: zIndex2 }}>
              <label style={{ color: "#000" }}>hash :</label>
              <input
                onFocus={() => setZIndex2(10)}
                onBlur={() => setZIndex2(0)}
                value={
                  transaction.recipt_info != null
                    ? transaction.recipt_info.hash
                    : ""
                }
              />{" "}
            </div>
          </div>
          <div className="item-container">
            <div id="address" className="item1" style={{ zIndex: zIndex2 }}>
              <label style={{ color: "#000" }}>amount :</label>
              <input
                type="number"
                placeholder="0"
                onFocus={() => setZIndex2(10)}
                onBlur={() => setZIndex2(0)}
                value={
                  transaction.recipt_info != null
                    ? transaction.recipt_info.amount
                    : ""
                }
              />{" "}
            </div>
          </div>

          <div className="item-container">
            <div id="address" className="item1" style={{ zIndex: zIndex1 }}>
              <label style={{ color: "#000" }}>From :</label>
              <input
                placeholder="ADDRESS"
                type="text"
                onFocus={() => setZIndex1(10)}
                onBlur={() => setZIndex1(0)}
                value={
                  transaction.recipt_info != null
                    ? transaction.recipt_info.from
                    : ""
                }
              />
            </div>
          </div>

          <div className="item-container">
            <div id="address" className="item1" style={{ zIndex: zIndex1 }}>
              <label style={{ color: "#000" }}>To :</label>
              <input
                placeholder="ADDRESS"
                type="text"
                onFocus={() => setZIndex1(10)}
                onBlur={() => setZIndex1(0)}
                value={
                  transaction.recipt_info != null
                    ? transaction.recipt_info.to
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Index;
