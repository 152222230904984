import React, { useEffect, useState } from "react";
import "./Finance.css";

import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AddIcon from "@material-ui/icons/Add";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { SuccessMessage, ErrorMessage } from "../../../../utils/messages";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPriceToPlanAction,
  GetActivePlanLiatAction,
} from "../../../../redux/plan/plan.action";

const Index = () => {
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.Plan);
  const loading = useSelector((state) => state.loading);

  const [amount, setAmount] = useState("");

  const handleSubmit = () => {
    if (amount != "") {
      dispatch({ type: "LOADING", payload: true });
      dispatch(AddPriceToPlanAction(amount, setAmount));
    } else {
      ErrorMessage("The value should not be empty");
    }
  };
  useEffect(() => {
    dispatch(GetActivePlanLiatAction());
  }, []);
  return (
    <div className="finance">
      <div className="title">
        <h4>Finance</h4>
      </div>

      <div className="actions">
        <button type="button">Your Plan</button>
        <button type="button" className="color-gold">
          {plan.active_plan != null ? plan.active_plan.planname : "Loading"}
        </button>
      </div>

      <div className="input-group">
        <label id="price">
          <LocalAtmIcon style={{ width: "24px", color: "#3c4572" }} />
          <span>stacking</span>
        </label>

        <div className="input-icon">
          <input
            type="number"
            placeholder="ex: 20 CYR"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />

          <span style={{ cursor: "pointer" }} onClick={handleSubmit}>
            <AddIcon />
          </span>
          {loading ? (
            <span>
              <div className="spinner-border"></div>
            </span>
          ) : null}
        </div>
      </div>

      <div className="commissions">
        <button type="button">
          <div className="percent">0.5 - 2</div>
          <span>COMMISSION</span>
        </button>

        <button type="button">
          <div className="percent">
            {" "}
            {plan.active_plan != null ? plan.active_plan.profi : "0"}
          </div>
          <span>Profit</span>
        </button>

        <button type="button">
          <div className="percent flex-column">
            {" "}
            {plan.active_plan != null ? plan.active_plan.amount : "0"}
            <p className="font-sm">CYR</p>
          </div>
          <span>amount</span>
        </button>
      </div>
    </div>
  );
};

export default Index;
