import React, { useState } from "react";
import CopyRight from "../../../common/copy-right/CopyRight";
import Logo from "./../img/logo.png";
import {
  VerifyEmailWrapper,
  VerifyEmailContainer,
  VerifyContentColumn,
  VerifyLogo,
  VerifyH1,
  VerifyEmailInformation,
  VrifyContactUs,
  ContactUsLink,
  TextCenter,
} from "./verifyEmailElements";
// import { IsCompletAction } from "../../../../redux/auth/login.action";
import "./style.css";
import { useDispatch } from "react-redux";
import { VerifyTwoAuthAction } from "../../../../redux/auth/login.action";
import { ErrorMessage } from "../../../../utils/messages";

const VerifyTwoAuth = ({ history }) => {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");

  const VerifyAccount = () => {
    if (code != "") {
      dispatch(VerifyTwoAuthAction(code, history));
    } else {
      ErrorMessage("Please Enter Authenticator Code");
    }
  };

  return (
    <>
      <VerifyEmailWrapper>
        <VerifyEmailContainer>
          <VerifyContentColumn>
            <VerifyLogo src={Logo} />
            <TextCenter>
              <VerifyH1>Please Verify Your Account</VerifyH1>
            </TextCenter>
            <TextCenter>
              <VerifyEmailInformation>
                Your almos there ! Please enter the verification code from
                authenticator app
              </VerifyEmailInformation>
            </TextCenter>
            <TextCenter>
              <input
                className="verify-email-input"
                placeholder="Enter Code..."
                onChange={(e) => setCode(e.target.value)}
              />
            </TextCenter>
            <TextCenter>
              <button className="verify-email-btn" onClick={VerifyAccount}>
                Verify Account
              </button>
            </TextCenter>

            <VrifyContactUs>
              Need help? <ContactUsLink> Contact Us </ContactUsLink>
            </VrifyContactUs>
          </VerifyContentColumn>
        </VerifyEmailContainer>
        <CopyRight />
      </VerifyEmailWrapper>
    </>
  );
};

export default VerifyTwoAuth;
