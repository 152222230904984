import React from "react";
import {
  VerifyEmailWrapper,
  VerifyEmailContainer,
  ContactUsLink,
  TextCenter,
} from "./verifyEmailElements";
import Logo from "./../img/logo.png";
import {
  VerifySucessColumn,
  LogoImg,
  VerifyContentColumn,
  VerifySucessH1,
  VerifyContentRow,
  NeedLink,
  ContinueBtn,
} from "./SucessElements";

const VerifySucess = () => {
  return (
    <>
      <VerifyEmailWrapper>
        <VerifyEmailContainer>
          <VerifySucessColumn>
            <LogoImg src={Logo} />
            <VerifyContentColumn>
              <TextCenter>
                <VerifySucessH1>Your account has been verified</VerifySucessH1>
              </TextCenter>
              <VerifyContentRow>
                <NeedLink>
                  if u need help ? <ContactUsLink>Contact Us</ContactUsLink>
                </NeedLink>
                <ContinueBtn onClick={() => window.location.replace("/")}>
                  Continue
                </ContinueBtn>
              </VerifyContentRow>
            </VerifyContentColumn>
          </VerifySucessColumn>
        </VerifyEmailContainer>
      </VerifyEmailWrapper>
    </>
  );
};

export default VerifySucess;
