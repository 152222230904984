import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { SuccessMessage } from "./../../../../utils/messages";

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    SuccessMessage("Successuly Exit From Wallet");
  });
  return <Redirect to="/" />;
};

export default Logout;
