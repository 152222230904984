import { BaseUrl } from "../apis/GlobalConfing";
const qr = require("querystring");

export const GetTicketList = async () => {
  const info = {
    token: localStorage.getItem("token"),
  };
  const response = await fetch(`${BaseUrl}/home/ticketlist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const GetTicketMessage = async (id) => {
  const info = {
    token: localStorage.getItem("token"),
    ticketid: id,
  };
  const response = await fetch(`${BaseUrl}/home/listchat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const SendMessage = async (id, message) => {
  const info = {
    token: localStorage.getItem("token"),
    ticketid: id,
    message,
  };
  const response = await fetch(`${BaseUrl}/home/userchat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(info),
  });
  const data = await response.json();

  return data;
};

export const SeenTicket = async (ticket_info) => {
  const response = await fetch(`${BaseUrl}/home/seentoticket`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qr.stringify(ticket_info),
  });
  const data = await response.json();

  return data;
};
