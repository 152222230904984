import React from "react";
import Layout from "../../common/layout";
import "./exchange.css";

const Exchange = () => {
  return (
    <Layout>
      <div className="mainContainer">
        <span id="back-to-top-anchor" />
        <p className="title"> exchange </p>
      </div>
    </Layout>
  );
};

export default Exchange;
